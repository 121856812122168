import { Component, OnInit } from '@angular/core';
import { MovieNightService, ToolsService, VoteService } from '../services';
import { LoadingController, AlertController } from '@ionic/angular';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Component({
	selector: 'app-my-movie-votes',
	templateUrl: 'my-movie-votes.page.html'
})
export class MyMovieVotes implements OnInit {
	public movieListLoaded = false;
	public myId = '';
	public votes = [];

	constructor(public loadingController: LoadingController, private toolsService: ToolsService,
		private movieNightService: MovieNightService, private alertController: AlertController,
		private router: Router, private localStorageService: LocalStorageService, private voteService: VoteService) {
			if (voteService.votedNotify.subscribe != null) {
				voteService.votedNotify.subscribe(vote => this.onDeleteVote());
			}
		}

		ngOnInit() {
			this.myId = this.localStorageService.get(environment.user_id_storage);
			this.getVotes(false);
		}

		public getVotes(emit){
			this.votes = [];
			if(!emit){
				this.toolsService.showLoading('Loading...').then(() => {
					this.movieNightService.getUserVotes().subscribe((response) => {
						if(response.success){
							for(let vote of response.votes){
								let date = new Date(vote.date_created);
								vote.date_created_formatted = date.toLocaleDateString("en-US");
								this.votes.push(vote);
							}
						}
						this.toolsService.hideLoading();
					})
				})
			} else {
				this.movieNightService.getUserVotes().subscribe((response) => {
					if(response.success){
						for(let vote of response.votes){
							let date = new Date(vote.date_created);
							vote.date_created_formatted = date.toLocaleDateString("en-US");
							this.votes.push(vote);
						}
					}
					this.toolsService.hideLoading();
				})
			}
		}

		public async newVotePrompt() {
			const alert = await this.alertController.create({
				header: 'Enter vote name:',
				inputs: [
					{
						name: 'title',
						type: 'text',
						placeholder: 'Title'
					}
				],
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							console.log('Confirm Cancel');
						}
					}, {
						text: 'Add',
						handler: (data) => {
							console.log(data)
							if(data.title.length > 0){
								this.toolsService.showLoading('Adding...').then(() => {
									this.movieNightService.addVote(data.title).subscribe((response) => {
										console.log(response);
										if(response.success){
											this.votes.unshift({id: response.vote_id, title: data.title, winning_movie_id: "", status: "New",
											owner_id: this.localStorageService.get(environment.user_id_storage), owner_img: this.localStorageService.get(environment.user_photo_storage)})
										}
										this.toolsService.hideLoading();
									})
								})
							}
						}
					}
				]
			});

			await alert.present();
		}

		private onDeleteVote(){
			this.getVotes(true);
		}
	}
