import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { ModalController } from '@ionic/angular';
import { MovieInfoModal } from '../modals/movie-info-modal/movie-info-modal.component';
import { ScheduleMovieNight } from '../modals/schedule-movie-night/schedule-movie-night.component';
import { HelpModal } from '../modals/help-modal/help-modal.component';
import { AddAttendeeModal } from '../modals/add-attendee-modal/add-attendee-modal.component';
import { AddPlaylistModal } from '../modals/add-playlist-modal/add-playlist-modal.component';
import { EditPlaylistModal } from '../modals/edit-playlist-modal/edit-playlist-modal.component';
// import { DonationModal } from '../modals/donation-modal/donation-modal.component';

@Injectable()
export class ModalService {

	constructor(private modalController: ModalController) {}

	public async infoModal(id) {
		const modal = await this.modalController.create({
			component: MovieInfoModal,
			componentProps: { id: id }
		});
		return await modal.present();
	}

	// public async donateModal() {
	// 	const modal = await this.modalController.create({
	// 		component: DonationModal
	// 	});
	// 	return await modal.present();
	// }

	public async scheduleModal(id, title, movie_night_id) {
		const modal = await this.modalController.create({
			component: ScheduleMovieNight,
			componentProps: { id: id, title: title, movie_night_id: movie_night_id }
		});
		return await modal.present();
	}

	public async editPlaylistModal(playlist) {
		const modal = await this.modalController.create({
			component: EditPlaylistModal,
			componentProps: { id: playlist.playlist_id, name: playlist.title, private: playlist.private }
		});
		return await modal.present();
	}

	public async helpModal() {
		const modal = await this.modalController.create({
			component: HelpModal
		});
		return await modal.present();
	}

	public async addAttendeeModal(id) {
		const modal = await this.modalController.create({
			component: AddAttendeeModal,
			componentProps: { movie_night_id: id }
		});
		return await modal.present();
	}

	public async newPlaylistModal() {
		const modal = await this.modalController.create({
			component: AddPlaylistModal
		});
		return await modal.present();
	}
}
