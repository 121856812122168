import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MembershipService, ToolsService, MovieNightService, ModalService } from '../services';
import { LoadingController, AlertController } from '@ionic/angular';

@Component({
	selector: 'app-subscriptions',
	templateUrl: 'subscriptions.page.html'
})
export class SubscriptionsPage implements OnInit {
	public subscriptions = [];
	public movie_subscriptions = [];
	public subsLoaded = false;

	constructor(private membershipService: MembershipService, private toolsService: ToolsService, public modalService: ModalService,
				private alertController: AlertController, private router: Router, private movieNightService: MovieNightService) {}

	ngOnInit() {
		this.getSubscriptions(null);
	}

	public getSubscriptions(event){
		this.toolsService.showLoading('Loading subscriptions...').then(() => {
			this.membershipService.getSubscriptions().subscribe((response) => {
				console.log(response);
				this.subscriptions = response.subscriptions;
				this.movie_subscriptions = response.movie_subscriptions;
				this.subsLoaded = true;
				this.toolsService.hideLoading();
				if(event != null){
					event.target.complete();
				}
			})
		})
	}

	public goToActor(sub){
		this.router.navigateByUrl('/search/actor/' + sub.actor_id + "?name=" + sub.name);
	}

	public async removeSubscription(sub){

		const alert = await this.alertController.create({
			header: 'Unsubscribe?',
			message: "You will no longer receive alerts when this actor has a new movie on the way.",
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					cssClass: 'secondary',
					handler: () => {
						console.log('Confirm Cancel');
					}
				}, {
					text: 'Confirm',
					handler: () => {
						this.toolsService.showLoading('Unsubscribing...').then(() => {
							this.membershipService.toggleSubscription(sub.actor_id, "false").subscribe((response) => {
								if(response.success){
									this.subscriptions.splice(this.subscriptions.indexOf(sub), 1);
									console.log("unsubscribed")
								}
								this.toolsService.hideLoading();
							})
						})
					}
				}
			]
		});

		await alert.present();
	}

	public async removeMovieSubscription(sub){

		const alert = await this.alertController.create({
			header: 'Remove alert?',
			message: "You will not be alerted when this movie arrives in theaters.",
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					cssClass: 'secondary',
					handler: () => {
						console.log('Confirm Cancel');
					}
				}, {
					text: 'Confirm',
					handler: () => {
						this.toolsService.showLoading('Removing alert...').then(() => {
							this.movieNightService.toggleMovieSubscription(sub.movie_id, 'false').subscribe((response) => {
								if(response.success){
									this.movie_subscriptions.splice(this.movie_subscriptions.indexOf(sub), 1);
									console.log("removed alert")
								}
								this.toolsService.hideLoading();
							})
						})
					}
				}
			]
		});

		await alert.present();
	}
}
