import { Component, Input, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { MovieNightService } from '../../services/movie-night.service';
import { ToolsService } from '../../services/tools.service';
import { ToastService } from '../../services/toast.service';
import { LoadingController } from '@ionic/angular';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/forkJoin';

@Component({
	selector: 'app-add-attendee-modal',
	templateUrl: 'add-attendee-modal.html'
})
export class AddAttendeeModal implements OnInit {

	@Input() movie_night_id: number;
	public movieInfo: any;
	public infoLoaded = false;
	public friends = [];
	public friendSearch = {name: ''};
	public invitedNames = "Myself"
	private loading: any;

	constructor(private modalCtrl: ModalController, private alertController: AlertController,
		private movieNightService: MovieNightService, private toolsService: ToolsService,
		private toastService: ToastService) { }

		ngOnInit() {
			this.toolsService.showLoading('Loading friends...').then(() => {
				this.movieNightService.getNotInvitedFriends(this.movie_night_id).subscribe((response) => {
					;
					for(let connection of response.connections){
						connection.name = decodeURI(connection.name);
						connection.selected = false;
						this.friends.push(connection)
						this.toolsService.hideLoading();
					}
					this.infoLoaded = true;
				});
			})
		}

		public async presentAlertConfirm() {
			const alert = await this.alertController.create({
				header: 'Schedule Movie Night!',
				message: this.formSendToMessage(),
				buttons: [
					{
						text: 'Yes!',
						cssClass: 'primary',
						handler: () => {
							this.inviteFriends(this.movie_night_id)
						}
					},
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: (blah) => {

						}
					}
				]
			});

			await alert.present();
		}

		public setInvitedFriendsText(){
			this.invitedNames = "Myself";

			for(let friend of this.friends){
				if(friend.selected){
					this.invitedNames += ", " + friend.name;
				}
			}
		}

		public changeFriendStatus(friend, checked){
			friend.selected = checked;
			this.setInvitedFriendsText();
		}

		public onSubmit() {
			this.presentAlertConfirm();
		}

		private formSendToMessage(){
			let output = "";
			let first = true;
			let numberFriends = 0;


			for(let friend of this.friends){
				if(friend.selected){
					numberFriends++;
					if(first){
						output = friend.name;
						first = false;
					} else {
						output += ", " + friend.name
					}
				}
			}

			if(numberFriends == 0){
				output = "Schedule this movie night for yourself?";
			} else {
				output = 'Would you like to send a movie night request to ' + output + "?";
			}

			return output;
		}

		private inviteFriends(movie_night_id){
			let friendsToInvite = [];
			let that = this;
			for(let friend of this.friends) {
				if(friend.selected) {
					friendsToInvite.push(friend)
				}
			}

			let allInvites = Observable.forkJoin(
				friendsToInvite.map(friend => this.movieNightService.addAttendee(friend.id, movie_night_id))
			);

			let allNotifications = Observable.forkJoin(
				friendsToInvite.map(friend => this.movieNightService.sendNotification("movie_night", friend.id, "", "", ""))
			);

			allInvites.subscribe((result => {
				allNotifications.subscribe((result => {
					this.toastService.shortToast("Invites sent for this movie night.");
					this.toolsService.hideLoading();
					this.closeModal();
				}))
			}))
		}

		public closeModal()
		{
			this.modalCtrl.dismiss();
		}

	}
