import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { FriendsService, ToolsService } from '../../services';

@Component({
	selector: 'app-user-profile',
	templateUrl: 'user-profile.page.html'
})
export class UserProfilePage {
	public profileId = '';
	public dataLoaded = false;
	public profile_pic = "https://www.themovienightapp.com/movie_night_uploads/no-image.jpg"
	public counts: any = {
		total: 0,
		watch: 0,
		seen: 0,
		date_joined: "",
		name: ""
	}

	constructor(private route: ActivatedRoute, private toolsService: ToolsService,
		private friendsService: FriendsService, public loadingController: LoadingController ) {}

		ngOnInit(): void {
			this.profileId = this.route.snapshot.paramMap.get('id');
			this.friendsService.getConnectionStats(this.profileId).subscribe((response) => {

				if(response.success){
					this.counts = {
						total: response.total_movies,
						watch: response.total_watch,
						seen: response.total_seen,
						nights: response.total_movie_nights,
						date_joined: this.toolsService.getDisplayDateAndYear(parseInt(response.date_joined)*1000),
						together: response.movies_watched_together.length,
						playlists: response.playlists.length,
						name: decodeURI(response.full_name)
					}

					this.profile_pic = response.profile_pic.replace("http://", "https://");
					this.dataLoaded = true;
				}
			})
		}
	}
