import { Injectable, EventEmitter } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { Http, Response } from '@angular/http';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class MembershipService {
	public loggedInNotify: EventEmitter<Object>;
	private baseApi = environment.movie_night_endpoint;

	constructor(private localStorageService: LocalStorageService, private http: Http, private appVersion: AppVersion) {
		this.loggedInNotify = new EventEmitter();
	}

	public isLoggedIn(){
		return this.localStorageService.get(environment.user_logged_in_storage) != null;
	}

	public isFirstLogin(){
		return this.localStorageService.get("firstLogin") == null;
	}

	public loggedInFirstTime(){
		this.localStorageService.set("firstLogin", true);
	}

	public getUserLocalPhoto(): string{
		return this.localStorageService.get(environment.user_photo_storage);
	}

	public setLoggedIn(user){
		this.localStorageService.set(environment.user_logged_in_storage, user.loggedIn);
		this.loggedInNotify.emit(user);
	}

	public setPublicProfile(toggle){
		this.localStorageService.set(environment.public_profile_setting, toggle);
	}

	public toggleAds(toggle){
		this.localStorageService.set(environment.show_ads, toggle);
	}

	public setUserId(id){
		this.localStorageService.set(environment.user_id_storage, id);
	}

	public setUserLocalPhoto(photo){
		this.localStorageService.set(environment.user_photo_storage, photo);
	}

	public setUserPhoto(path){
		this.setUserLocalPhoto(path);
		return this.http.get(this.baseApi + "update_user_picture_path.php", { search: { u: this.localStorageService.get(environment.user_id_storage), path: path } }).map((res: Response) => res.json())
	}

	public updateProfileSetting(value){
		return this.http.get(this.baseApi + "update_profile_setting.php", { search: { u: this.localStorageService.get(environment.user_id_storage), value: value } }).map((res: Response) => res.json())
	}

	public getAccountInfo(){
		return this.http.get(this.baseApi + "get_user_account_info.php", { search: { u: this.localStorageService.get(environment.user_id_storage) } }).map((res: Response) => res.json())
	}

	public getSubscriptions(){
		return this.http.get(this.baseApi + "get_user_subscriptions.php", { search: { u: this.localStorageService.get(environment.user_id_storage) } }).map((res: Response) => res.json())
	}

	public updateAccountInfo(name, email, username, new_password){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			name: name,
			username: username,
			email: email,
			password: new_password
		}

		return this.http.get(this.baseApi + "update_account_info.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public updateLastLogin(){
		this.appVersion.getVersionNumber().then(version => {
			let dataParams = {
				u: this.localStorageService.get(environment.user_id_storage),
				version: version
			}

			return this.http.get(this.baseApi + "update_last_login.php", { search: dataParams }).subscribe((response) => {});
		});
	}

	public login(email, password){
		return this.http.get(this.baseApi + "authenticate_user.php", { search: { email: email, pass: password } }).map((res: Response) => res.json())
	}

	public signUp(name, email, username, password){
		let dataParams = {
			name: encodeURI(name),
			username: encodeURI(username),
			email: encodeURI(email),
			pass: encodeURI(password)
		}

		return this.http.get(this.baseApi + "sign_up_user.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public checkUsername(username){
		return this.http.get(this.baseApi + "check_username.php", { search: { username: encodeURI(username) } }).map((res: Response) => res.json())
	}

	public getUserSettings(){
		return this.http.get(this.baseApi + "get_user_ad_status.php", { search: { u: this.localStorageService.get(environment.user_id_storage) } }).map((res: Response) => res.json());
	}

	public logout(){
		let user = {
			image: "",
			loggedIn: false
		}
		this.localStorageService.remove(environment.user_logged_in_storage);
		this.loggedInNotify.emit(user);
	}

	public toggleSubscription(actor_id, toggle){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			a: actor_id,
			s: toggle
		}

		return this.http.get(this.baseApi + "toggle_actor_subscription.php", { search: dataParams }).map((res: Response) => res.json())
	}
}
