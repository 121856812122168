import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { MovieNightService } from '../../services/movie-night.service';
import { ToolsService } from '../../services/tools.service';
// import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx'

@Component({
	selector: 'app-donation-modal',
	templateUrl: 'donation-modal.html'
})
export class DonationModal implements OnInit {
	@Input() id: number;
	public products: any[] = [];


	constructor(private modalCtrl: ModalController, private movieNightService: MovieNightService,
		private toolsService: ToolsService, private platform: Platform) { }

		ngOnInit() {
			let that = this;
			// this.toolsService.showLoading('Loading...').then(() => {

			// 	this.iap.register({
			// 		id: "ad_donation.1",
			// 		alias: "Pay $1.00",
			// 		type: this.iap.NON_CONSUMABLE
			// 	});
			// 	this.products.push(this.iap.get("Pay $1.00"))

			// 	console.log(this.products);
			// 	this.iap.register({
			// 		id: "ad_donation.2",
			// 		alias: "Pay $2.00",
			// 		type: this.iap.NON_CONSUMABLE
			// 	});
			// 	this.products.push(this.iap.get("Pay $2.00"));
			// 	console.log(this.products);

			// 	this.iap.register({
			// 		id: "ad_donation.3",
			// 		alias: "Pay $3.00",
			// 		type: this.iap.NON_CONSUMABLE
			// 	});
			// 	this.products.push(this.iap.get("Pay $3.00"));
			// 	console.log(this.products);

			// 	this.iap.register({
			// 		id: "ad_donation.5",
			// 		alias: "Pay $5.00",
			// 		type: this.iap.NON_CONSUMABLE
			// 	});
			// 	this.products.push(this.iap.get("Pay $5.00"));
			// 	console.log(this.products);

			// 	this.products.forEach(product => {
			// 		this.iap.when(product.id).owned(function(product) {
			// 		    console.log("product purchased", product);
			// 				// 	that.admobFree.banner.remove();
			// 				// 	that.movieNightService.disableUserAds().subscribe((response) => {});
			// 				// 	let deviceType = "Google In App";
			// 				// 	if(this.platform.is('ios')){
			// 				// 		deviceType = "Apple In App";
			// 				// 	}
			// 				// 	that.movieNightService.addDonationRecord(id, payment.receipt, deviceType).subscribe((response) => {})
			// 		    product.finish();
			// 		});
			// 	});

			// 	console.log(this.products);

			// 	that.toolsService.hideLoading();
			// })
		}

		public closeModal() {
			this.modalCtrl.dismiss();
		}

		public restorePurchases(){
			// this.iap.restorePurchases()
			// .then(function (data) {
			// 	console.log(data);
			// 	var string = "The following purchases have been restored: ";
			// 	var first = true;
			// 	if(data.length > 0){
			// 		for(let product of data){
			// 			if(first){
			// 				first = false;
			// 				string += product.productId;
			// 			} else {
			// 				string += ", " + product.productId;
			// 			}
			// 		}
			// 	} else {
			// 		var string = "There is nothing to restore.";
			// 	}
			//
			// 	alert(string);
			// })
			// .catch(function (err) {
			// 	console.log(err);
			// });
		}

		public inAppPayment(alias){
			// this.iap.order(alias);
		}
	}
