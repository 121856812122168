import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { HttpModule, RequestOptions } from '@angular/http';
import { LocalStorageModule } from 'angular-2-local-storage';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
// import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx'

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx'

const config = {
	apiKey: "AIzaSyDqU-lyzDkNb6bngTf2IlWghY4-sNTbf6A",
	authDomain: "movie-night-29558.firebaseapp.com",
	databaseURL: "https://movie-night-29558.firebaseio.com",
	projectId: "movie-night-29558",
	storageBucket: "movie-night-29558.appspot.com",
	messagingSenderId: "1063066991196",
	appId: "1:1063066991196:web:28d15cd421410f4a"
};

// movie night modules
import { AppRoutingModule } from './app-routing.module';
import { MyMoviesRoutingModule } from './my-movies/my-movies-routing.module';
import { UserProfileRoutingModule } from './connections/user-profile/user-profile-routing.module';
import { AppComponent } from './app.component';
import { AboutPage } from './about/about.page';
import { MaintenencePage } from './maintenence/maintenence.page';
import { NotificationsPage } from './notifications/notifications.page';
import { PlaylistPage } from './playlist/playlist.page';
import { MyMovies } from './my-movies/my-movies.page';
import { LoginPage } from './login/login.page';
import { MyAccountPage } from './my-account/my-account.page';
import { ComparePage } from './connections/compare/compare.page';
import { EditSettingsPage } from './my-account/edit-settings/edit-settings.page';
import { ChangeInfoPage } from './my-account/change-info/change-info.page';
import { FindFriendsPage } from './find-friends/find-friends.page';
import { SearchPage } from './search/search.page';
import { RecommendationsPage } from './recommendations/recommendations.page';
import { ActorProfilePage } from './search/actor-profile/actor-profile.page';
import { SubscriptionsPage } from './subscriptions/subscriptions.page';
import { ConnectionsPage } from './connections/connections.page';
import { UserProfilePage } from './connections/user-profile/user-profile.page';
import { FeedbackPage } from './feedback/feedback.page';
import { NewsFeedPage } from './news-feed/news-feed.page';
import { MyMovieNights } from './my-movie-nights/my-movie-nights.page';
import { MovieNightPage } from './my-movie-nights/movie-night/movie-night.page';
import { MyMovieVotes } from './movie-votes/my-movie-votes.page';
import { MovieVoteDetailsPage } from './movie-votes/movie-vote-details/movie-vote-details.page';

// Modals
import { AddAttendeeModule } from './modals/add-attendee-modal/add-attendee-modal.module';
import { MovieInfoModule } from './modals/movie-info-modal/movie-info-modal.module';
import { HelpModalModule } from './modals/help-modal/help-modal.module';
import { ScheduleMovieNightModule } from './modals/schedule-movie-night/schedule-movie-night.module';
import { AddPlaylistModalModule } from './modals/add-playlist-modal/add-playlist-modal.module';
import { EditPlaylistModalModule } from './modals/edit-playlist-modal/edit-playlist-modal.module';
import { AddMoviesPlaylistModalModule } from './modals/add-movies-playlist-modal/add-movies-playlist-modal.module';
import { AddFriendsPlaylistModalModule } from './modals/add-friends-playlist-modal/add-friends-playlist-modal.module';
import { DonationModalModule } from './modals/donation-modal/donation-modal.module';

// components
import { ComponentsModule } from './components/components.module';

import { MovieListModule } from './my-movies/my-movies.module';
import { Toast } from '@ionic-native/toast/ngx';

// interceptors
import { CustomRequestOptions } from './interceptors/http.interceptor';

// services
import { MovieNightService, ToolsService, SearchService,
	ToastService, MembershipService, FriendsService,
	ModalService, AuthGuard, FcmService, MaintenenceGaurd,
	VoteService, PlaylistService } from './services';

	import { ScrollingModule } from '@angular/cdk/scrolling';
	import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

	@NgModule({
		declarations: [
			AppComponent,
			AboutPage,
			FeedbackPage,
			MyMovieNights,
			NewsFeedPage,
			MovieNightPage,
			MyMovieVotes,
			MovieVoteDetailsPage,
			SearchPage,
			RecommendationsPage,
			LoginPage,
			MyMovies,
			SubscriptionsPage,
			ConnectionsPage,
			UserProfilePage,
			NotificationsPage,
			PlaylistPage,
			FindFriendsPage,
			MyAccountPage,
			EditSettingsPage,
			ChangeInfoPage,
			ComparePage,
			ActorProfilePage,
			MaintenencePage
		],
		imports: [
			BrowserModule,
			ReactiveFormsModule,
			HttpModule,
			RouterModule,
			FormsModule,
			AddAttendeeModule,
			MovieInfoModule,
			HelpModalModule,
			AddPlaylistModalModule,
			EditPlaylistModalModule,
			ScheduleMovieNightModule,
			AddMoviesPlaylistModalModule,
			AddFriendsPlaylistModalModule,
			DonationModalModule,
			MovieListModule,
			ScrollingModule,
			ExperimentalScrollingModule,
			ComponentsModule,
			IonicModule.forRoot(),
			LocalStorageModule.forRoot({
				prefix: 'movie-night',
				storageType: 'localStorage'
			}),
			AngularFireModule.initializeApp(config),
			AngularFirestoreModule,
			AppRoutingModule,
			MyMoviesRoutingModule,
			UserProfileRoutingModule
		],
		providers: [
			StatusBar,
			SplashScreen,
			SocialSharing,
			MovieNightService,
			ToolsService,
			SearchService,
			Toast,
			Camera,
			ToastService,
			FileTransfer,
			Calendar,
			AppVersion,
			File,
			MembershipService,
			FriendsService,
			AuthGuard,
			MaintenenceGaurd,
			VoteService,
			PlaylistService,
			ModalService,
			// FirebaseX,
			GoogleAnalytics,
			FcmService,
			Network,
			// InAppPurchase2,
			{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
			{ provide: RequestOptions, useClass: CustomRequestOptions }
		],
		bootstrap: [AppComponent]
	})
	export class AppModule {}
