import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-help-modal-modal',
	templateUrl: 'help-modal.html'
})
export class HelpModal {

	constructor(private modalCtrl: ModalController) { }

	public closeModal()
	{
		this.modalCtrl.dismiss();
	}

}
