import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { FriendSearchPipe } from './friend-search.pipe';
import { MovieSearchPipe } from './movie-search.pipe';

@NgModule({
	imports: [
		CommonModule,
		IonicModule
	],
	declarations: [FriendSearchPipe, MovieSearchPipe],
	exports: [
		FriendSearchPipe,
		MovieSearchPipe
	],
	entryComponents: []
})
export class PipeModule {}
