import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { MembershipService, ModalService } from '../../services';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-edit-settings',
	templateUrl: 'edit-settings.page.html'
})
export class EditSettingsPage implements OnInit {
	public showImport = false;
	public publicProfile = true;
	public darkMode = false;
	public showAdButton = false;
	public zipCode = "";

	constructor(private membershipService: MembershipService, private localStorageService: LocalStorageService,
	public modalService: ModalService, private platform: Platform) {}

	ngOnInit() {
		if(this.platform.is('android') || this.platform.is('ios')){
			this.showAdButton = true;
		}

		if(this.localStorageService.get(environment.show_import_setting) != null){
			this.showImport = this.localStorageService.get(environment.show_import_setting);
		} else {
			this.showImport = false;
		}

		if(this.localStorageService.get(environment.public_profile_setting) != null){
			this.publicProfile = this.localStorageService.get(environment.public_profile_setting);
		} else {
			this.publicProfile = true;
		}

		if(this.localStorageService.get(environment.dark_mode) != null){
			this.darkMode = this.localStorageService.get(environment.dark_mode);
		} else {
			this.darkMode = false;
		}

		if(this.localStorageService.get(environment.user_zip_code) != null){
			this.zipCode = this.localStorageService.get(environment.user_zip_code);
		}
	}

	public onImportChange(event) {
		this.localStorageService.set(environment.show_import_setting, event.detail.checked ? true : false);
	}

	public onDarkChange(event) {
		this.localStorageService.set(environment.dark_mode, event.detail.checked ? true : false);
		document.body.classList.toggle('dark', event.detail.checked);
	}

	public onProfileChange(event) {
		this.membershipService.updateProfileSetting(event.detail.checked ? "True" : "False").subscribe((response) => {})
		this.localStorageService.set(environment.public_profile_setting, event.detail.checked);
	}

	public onZipChange(event) {
		this.localStorageService.set(environment.user_zip_code, event.detail.value);
	}
}
