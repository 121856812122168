import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { PipeModule } from '../../pipes/pipes.module';

import { AddAttendeeModal } from './add-attendee-modal.component';

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		FormsModule,
		PipeModule
	],
	declarations: [AddAttendeeModal],
	entryComponents: [
		AddAttendeeModal
	]
})
export class AddAttendeeModule {}
