import { Component, Input, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MovieNightService } from '../../services/movie-night.service';
import { ToolsService } from '../../services/tools.service';
import { ToastService } from '../../services/toast.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/forkJoin';

@Component({
	selector: 'app-add-friends-playlist-modal',
	templateUrl: 'add-friends-playlist-modal.html'
})
export class AddFriendsPlaylistModal implements OnInit {
	@Input() id: number;
	public friends = [];

	constructor(private modalController: ModalController, private formBuilder: FormBuilder,
		private movieNightService: MovieNightService, private toastService: ToastService,
		private toolsService: ToolsService, private alertController: AlertController) { }

		ngOnInit() {
			this.toolsService.showLoading('Loading friends...').then(() => {
				this.movieNightService.getPossibleFriendsForPlaylist(this.id).subscribe((response) => {

					for(let friend of response.connections){
						friend.isSelected = false;
						friend.name = decodeURI(friend.name);
						this.friends.push(friend);
					}
					this.toolsService.hideLoading()
				})
			})
		}

		public updateFriend(friend, event){
			friend.isSelected = event.detail.checked;
		}

		public onSubmit() {
			let friendsToInvite = [];
			let playlistId = this.id;

			for(let friend of this.friends){
				if(friend.isSelected){
					friendsToInvite.push(friend);
				}
			}

			if(friendsToInvite.length != 0){

				let allInvites = Observable.forkJoin(
					friendsToInvite.map(friend => this.movieNightService.adduserToPlaylist(friend.id, playlistId))
				);

				this.toolsService.showLoading('Adding friends...').then(() => {
					allInvites.subscribe((result => {
						this.toolsService.hideLoading();
						this.closeModal();
						this.toastService.shortToast("Added " + friendsToInvite.length + " friend(s) to playlist.");
					}))
				})
			} else {
				this.toolsService.showAlert('No friends selected', 'You must select friends to add first.');
			}
		}

		public closeModal()
		{
			this.modalController.dismiss();
		}

	}
