import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-about',
	templateUrl: 'about.page.html'
})
export class AboutPage implements OnInit {

	constructor() {}

	ngOnInit() {

	}
}
