import { Component, Input, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MovieNightService } from '../../services/movie-night.service';
import { ToolsService } from '../../services/tools.service';
import { ToastService } from '../../services/toast.service';

@Component({
	selector: 'app-edit-playlist-modal',
	templateUrl: 'edit-playlist-modal.html'
})
export class EditPlaylistModal implements OnInit {
	@Input() id: number;
	@Input() name: number;
	@Input() private: boolean;
	public newPlaylistForm: FormGroup;

	public infoLoaded = false;
	private loading: any;

	constructor(private modalController: ModalController, private formBuilder: FormBuilder,
		private movieNightService: MovieNightService, private toastService: ToastService,
		private toolsService: ToolsService, private alertController: AlertController) { }

		ngOnInit() {
			this.newPlaylistForm = this.formBuilder.group({
				title: [this.name, Validators.required],
				isPrivate: [this.private]
			});
		}

		public onPrivateChange(event){
			this.f.isPrivate.setValue(event.detail.checked);
		}

		public get f() { return this.newPlaylistForm.controls; }

		public onSubmit() {

			if(!this.newPlaylistForm.valid){
				return;
			}

			this.toolsService.showLoading('Updating playlist...').then(() => {
				this.movieNightService.editPlaylist(this.f.title.value, this.f.isPrivate.value ? '1' : '0', this.id).subscribe((response) => {
					this.movieNightService.playlistUpdates.emit(this.f.title.value);
					this.toolsService.hideLoading();
					this.closeModal();
				})
			})
		}

		public async deletePlaylist(){

			const alert = await this.alertController.create({
				header: 'Delete playlist?',
				message: "Are you sure you would like to delete this playlist?",
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							console.log('Confirm Cancel');
						}
					}, {
						text: 'Yes',
						handler: () => {
							this.toolsService.showLoading('Deleteing...').then(() => {
								this.movieNightService.deletePlaylist(this.id).subscribe((response) => {
									if(response.success){
										this.movieNightService.playlistUpdates.emit("deleted");
										this.closeModal();
									}
									this.toolsService.hideLoading();
								})
							})
						}
					}
				]
			});

			await alert.present();
		}

		public closeModal()
		{
			this.modalController.dismiss();
		}

	}
