import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {

	constructor(public router: Router, private localStorageService: LocalStorageService) {}

	canActivate(): boolean {
		const loggedIn = this.localStorageService.get(environment.user_logged_in_storage);
		if (!loggedIn) {
			this.router.navigate(['login']);
			return false;
		}
		return true;
	}
}
