import { Injectable } from '@angular/core';
// import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { Platform } from '@ionic/angular';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class FcmService {
	private baseApi = environment.movie_night_endpoint;

	constructor(
		// private firebase: FirebaseX,
		private platform: Platform,
		private localStorageService: LocalStorageService,
		private http: Http) {}

		async getToken() {
			let token;

			if (this.platform.is('android')) {
				// this.firebase.getToken().then((token) => {
				// 	this.saveToken(token, "Android");
				// })
			}

			if (this.platform.is('ios')) {
				// this.firebase.hasPermission().then((hasPermission) => {
				// 	if(hasPermission){
				// 		this.firebase.getToken().then((token) => {
				// 			this.saveToken(token, "iOS");
				// 		}).catch((err) => {
				// 	        console.log(err.message); // something bad happened
				// 	    });
				// 	} else {
				// 		this.firebase.grantPermission().then((hasPermission) => {
				// 		    if(hasPermission){
				// 				this.firebase.getToken().then((token) => {
				// 					this.saveToken(token, "iOS");
				// 				}).catch((err) => {
				// 			        console.log(err.message); // something bad happened
				// 			    });
				// 			}
				// 		});
				// 	}
				// });
			}
		}

		private saveToken(token, type) {
			if (!token) return;

			let dataParams = {
				u: this.localStorageService.get(environment.user_id_storage),
				regid: token,
				type: type
			}

			return this.http.get(this.baseApi + "update_user_device_id.php", { search: dataParams }).subscribe((response) => {

			})
		}

		onNotifications() {
			// return this.firebase.onMessageReceived();
		}
	}
