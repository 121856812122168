import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { AddMoviesPlaylistModal } from '../modals/add-movies-playlist-modal/add-movies-playlist-modal.component'
import { AddFriendsPlaylistModal } from '../modals/add-friends-playlist-modal/add-friends-playlist-modal.component'
import { MovieInfoModal } from '../modals/movie-info-modal/movie-info-modal.component'
import { ScheduleMovieNight } from '../modals/schedule-movie-night/schedule-movie-night.component'
import { MovieNightService } from '../services/movie-night.service';
import { VoteService } from '../services/vote.service';
import { ToolsService } from '../services/tools.service';
import { ToastService } from '../services/toast.service';

@Component({
	selector: 'app-playlist',
	templateUrl: 'playlist.html'
})
export class PlaylistPage implements OnInit {
	public movieInfo: any;
	public infoLoaded = false;
	public movies = [];
	public id = '';
	public name = '';
	public isOwner = false;

	constructor(private modalController: ModalController, private movieNightService: MovieNightService,
		private actionSheetController: ActionSheetController, private toastService: ToastService,
		private toolsService: ToolsService, private route: ActivatedRoute, private voteService: VoteService) {
			if (movieNightService.playlistUpdates.subscribe != null) {
				movieNightService.playlistUpdates.subscribe(movie => {
					this.getMovies(true);
				});
			}
		}

		ngOnInit() {
			this.id = this.route.snapshot.paramMap.get('id');
			this.route.queryParams.subscribe(params => {
				this.name = params['title'];
				this.isOwner = params['owner'] == 'true';
			});
			this.getMovies(false);
		}

		private getMovies(emitter){
			this.movies = [];
			this.infoLoaded = false;
			if(!emitter){
				this.toolsService.showLoading('Loading playlist...').then(() => {
					this.movieNightService.getPlaylistMovies(this.id).subscribe((response) => {
						if(response.success){
							console.log(response)
							for(let movie of response.movies){
								this.movies.push(movie._data);
							}
						}
						this.infoLoaded = true;
						this.toolsService.hideLoading();
					})
				})
			} else {
				this.movieNightService.getPlaylistMovies(this.id).subscribe((response) => {
					if(response.success){
						for(let movie of response.movies){
							this.movies.push(movie._data);
						}
					}
					this.infoLoaded = true;
					this.toolsService.hideLoading();
				})
			}
		}

		public async presentActionSheet(movie) {
			let buttons = [];

			if(this.isOwner){
				buttons.push({
					text: 'Remove from playlist',
					role: 'destructive',
					icon: 'trash',
					handler: () => {
						this.movieNightService.deletePlaylistMovie(this.id, movie.id).subscribe((response) => {
							this.movies.splice(this.movies.indexOf(movie), 1);
							this.toastService.shortToast(movie.original_title + " deleted from playlist.");
						})
					}
				})
			}

			buttons.push({
				text: 'Add to Vote',
				icon: 'bulb',
				handler: () => {
					this.voteService.addMovieToVote(movie);
				}
			})

			if(!movie.already_added) {
				buttons.push({
					text: 'Add to watch list',
					icon: 'glasses',
					role: '',
					handler: () => {
						this.movieNightService.addMovie(movie, false).subscribe((response) => {
							movie.already_added = true;
							this.toastService.shortToast(movie.original_title + " added to watch list.");
						})
					}
				})

				buttons.push({
					text: 'Add to seen list',
					icon: 'glasses',
					role: '',
					handler: () => {
						this.movieNightService.addMovie(movie, true).subscribe((response) => {
							movie.already_added = true;
							this.toastService.shortToast(movie.original_title + " added to seen list.");
						})
					}
				})
			} else {
				if(movie.seen){
					buttons.push({
						text: 'Not watched',
						icon: 'glasses',
						role: '',
						handler: () => {
							this.movieNightService.updateMovieStatus(movie.id, false).subscribe((response) => {
								;
								this.toastService.shortToast("Movie marked as not watched.");
							});
						}
					})
				} else {
					buttons.push({
						text: 'Watched',
						icon: 'glasses',
						role: '',
						handler: () => {
							this.movieNightService.updateMovieStatus(movie.id, true).subscribe((response) => {
								;
								this.toastService.shortToast("Movie marked as watched.");
							});
						}
					})
				}
			}
			const actionSheet = await this.actionSheetController.create({
				header: 'Movie Options',
				buttons: buttons
			});
			await actionSheet.present();
		}

		public async addFriends() {
			const modal = await this.modalController.create({
				component: AddFriendsPlaylistModal,
				componentProps: { id: this.id }
			});
			return await modal.present();
		}

		public async addMovies() {
			const modal = await this.modalController.create({
				component: AddMoviesPlaylistModal,
				componentProps: { id: this.id }
			});
			return await modal.present();
		}

		public async infoModal(id) {
			const modal = await this.modalController.create({
				component: MovieInfoModal,
				componentProps: { id: id }
			});
			return await modal.present();
		}

		public async scheduleModal(id, title, movie_night_id) {
			const modal = await this.modalController.create({
				component: ScheduleMovieNight,
				componentProps: { id: id, title: title, movie_night_id: movie_night_id }
			});
			return await modal.present();
		}

		public closeModal()
		{
			this.modalController.dismiss();
		}

	}
