import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FriendsService, ToolsService, MovieNightService, ModalService, ToastService, VoteService, PlaylistService } from '../../services';
import { LoadingController, ActionSheetController } from '@ionic/angular';

@Component({
	selector: 'app-compare',
	templateUrl: 'compare.page.html'
})
export class ComparePage implements OnInit {
	public matches = [];
	public non_matches = [];
	public unwatched_matches = [];
	public movies = [];
	public dataLoaded = false;
	public list_types = [
		{name:'Both Unwatched', value: "both_unwatched"},
		{name:'Both Have', value: "both"},
		{name:'Differences', value: 'differences'}
	];
	public profileId = '';
	public list_type = "both_unwatched";

	constructor(private friendsService: FriendsService, private toolsService: ToolsService,
		private movieNightService: MovieNightService, private route: ActivatedRoute,
		private modalService: ModalService, private actionSheetController: ActionSheetController,
		private toastService: ToastService, private voteService: VoteService, private playlistService: PlaylistService) {}


		ngOnInit() {
			this.profileId = this.route.snapshot.paramMap.get('id');
			this.toolsService.showLoading('Loading movies...').then(() => {
				this.movieNightService.getCompareList(this.profileId).subscribe((response) => {
					if(response.success){
						console.log(response)
						for(let movie of response.movies){
							this.matches.push(movie._data);
						}
						for(let movie of response.non_matches){
							this.non_matches.push(movie._data);
						}
						for(let movie of response.unwatched_matches){
							this.movies.push(movie._data);
							this.unwatched_matches.push(movie._data);
						}
					}
					this.toolsService.hideLoading();
					this.dataLoaded = true;
				})
			});
		}

		public addMovie(movie){
			movie.already_added = true;
			this.toastService.shortToast(movie.original_title + " added to list.");
		}

		public deleteMovie(movie){
			this.movies.splice(this.movies.indexOf(movie), 1);

			debugger;
			if(this.list_type == "both"){
				this.matches.splice(this.matches.indexOf(movie), 1);
			} else if(this.list_type == "differences") {
				this.non_matches.splice(this.non_matches.indexOf(movie), 1);
			} else {
				this.unwatched_matches.splice(this.unwatched_matches.indexOf(movie), 1);
			}

			this.toastService.shortToast(movie.original_title + " was deleted.");
		}

		public changeList(){
			if(this.list_type == "both"){
				this.movies = this.matches;
			} else if(this.list_type == "differences") {
				this.movies = this.non_matches;
			} else {
				this.movies = this.unwatched_matches;
			}
		}
	}
