import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserProfilePage } from './user-profile.page';

const routes: Routes = [
	{
		path: 'connections/user-profile/:id',
		component: UserProfilePage,
		children: [
			{
				path: 'profile',
				children: [
					{
						path: '',
						loadChildren: './movies-tab/movies-tab.module#ProfileMoviesModule'
					}
				]
			},
			{
				path: 'together',
				children: [
					{
						path: '',
						loadChildren: './movies-together/movies-together.module#MoviesTogetherModule'
					}
				]
			},
			{
				path: 'playlists',
				children: [
					{
						path: '',
						loadChildren: './playlists-tab/playlists-tab.module#FriendPlaylistsTabModule'
					}
				]
			},
			{
				path: '',
				redirectTo: '/connections/user-profile/:id/movies',
				pathMatch: 'full'
			}
		]
	},
	{
		path: '',
		redirectTo: '/connections/user-profile/:id/movies',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class UserProfileRoutingModule { }
