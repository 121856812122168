import { Component, OnInit } from '@angular/core';
import { Response, Http, RequestOptions, Headers } from '@angular/http';
import { MovieNightService, MembershipService } from '../services';
import { environment } from '../../environments/environment';

@Component({
	selector: 'app-my-movies',
	templateUrl: 'my-movies.page.html'
})
export class MyMovies implements OnInit {

	public counts = {
		total_movies: 0,
		watch: 0,
		seen: 0,
		playlists: 0,
		notifications: 0
	}

	constructor(private http: Http, private movieNightService: MovieNightService, private membershipService: MembershipService) {}

	ngOnInit() {
		this.movieNightService.getUserMovieTotals().subscribe((response) => {

			if(response.success)
			{
				this.counts.total_movies = response.total_movies;
				this.counts.watch = response.total_watch;
				this.counts.seen = response.total_seen;
				this.counts.playlists = response.total_playlists;
			}
		});

		this.getNumberOfNotifications();
		setInterval(() => {
			this.getNumberOfNotifications();
		}, environment.periodic_checks_timer);
	}

	private getNumberOfNotifications(){
		this.movieNightService.getUserNotifications().subscribe((response) => {
			this.counts.notifications = response.notifications.length;
		});
	}
}
