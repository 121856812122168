import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MovieNightService, ToastService, ToolsService } from '../services';

@Component({
	selector: 'app-recommendations',
	templateUrl: 'recommendations.page.html'
})
export class RecommendationsPage implements OnInit {
	public search_results = [];
	public searchComplete = false;

	constructor(private route: ActivatedRoute, private toastService: ToastService, private movieNightService: MovieNightService,
				private toolsService: ToolsService) {}

		ngOnInit() {
			let movieId = this.route.snapshot.paramMap.get('id');
			this.getRecommendations(movieId);
		}

		public addMovie(movie){
			movie.already_added = true;
			this.toastService.shortToast(movie.title + " added to list.");
		}

		public deleteMovie(movie){
			movie.already_added = false;
			this.toastService.shortToast(movie.original_title + " was deleted.");
		}

		public getRecommendations(id){
			this.search_results = [];
			this.searchComplete = false;

			this.toolsService.showLoading('Searching...').then(() => {
				this.movieNightService.getMovieRecommendations(id).subscribe((response) => {
					if(response.movies.length > 0)
					{
						for(let movie of response.movies){
							this.search_results.push(movie._data);
						}
						this.searchComplete = true;
					}
					this.toolsService.hideLoading();
				});
			})
		}
	}
