import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController } from '@ionic/angular';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../../environments/environment';
import { MovieNightService, ToastService, FriendsService, ToolsService, ModalService } from '../services';

@Component({
	selector: 'app-notifications',
	templateUrl: 'notifications.page.html'
})
export class NotificationsPage implements OnInit {
	public notificationsLoaded = false;;
	public notifications = [];

	constructor(private movieNightService: MovieNightService,
		private router: Router, public toastService: ToastService,
		private friendsService: FriendsService, private localStorageService: LocalStorageService,
		private toolsService: ToolsService, public modalService: ModalService) {}

		ngOnInit() {
			this.toolsService.showLoading('Loading notifications...').then(() => {
				this.getNotifications(null);
			})
		}

		public addToCalendar(notification){
			let theDate = new Date(parseInt(notification.movie_night_date.split("|")[0])*1000);
			let startDate = new Date(theDate.getFullYear(), theDate.getMonth(), theDate.getDate(), notification.full_time.getHours(), notification.full_time.getMinutes(), 0, 0);
			let endDate = new Date(theDate.getFullYear(), theDate.getMonth(), theDate.getDate(), (notification.full_time.getHours()+2), notification.full_time.getMinutes(), 0, 0);

			this.toolsService.addToCalendar(notification.movie_title, notification.message, startDate, endDate).then((response) => {
				this.toastService.shortToast("Movie night added to calendar to see "+notification.movie_title+"!");
				this.router.navigateByUrl('/movie-nights/movie-night/' + notification.link_id);
				this.dismissNotification(notification);
			})
		}

		public getNotifications(event){
			this.notifications = [];
			this.movieNightService.getUserNotifications().subscribe((response) => {
				console.log(response)
				if(response.success){
					for(let notification of response.notifications){
						notification.text = decodeURI(notification.text);
						if(notification.type == "Movie Night" || notification.type == "Movie Night Confirm") {
							var rawTime = notification.movie_night_date.split("|")[1];

							if(typeof rawTime !== 'undefined')
							{
								var d = new Date();
								d.setHours(rawTime.split(":")[0]);
								d.setMinutes(rawTime.split(":")[1]);

								notification.time = this.toolsService.getDisplayTime(d);
								notification.date = this.toolsService.getDisplayDate(parseInt(notification.movie_night_date.split("|")[0]))
								notification.full_time = d;
							}

							if(notification.image){
								notification.image = notification.image.replace("http://", "https://");
							}
						}

						this.notifications.push(notification);
					}
				}
				this.notificationsLoaded = true;
				this.toolsService.hideLoading();
				if(event != null){
					event.target.complete();
				}
			})
		}

		public goToPlaylist(notification){
			this.router.navigateByUrl('/playlist/' + notification.link_id + "?title=" + notification.title + "&owner=true");
			this.dismissNotification(notification);
		}

		public updateConnectionRequest(notification, decision){
			this.friendsService.updateConnectionRequest(this.localStorageService.get(environment.user_id_storage), notification.link_id, decision, "true").subscribe((response) => {
				if(response.success){
					this.dismissNotification(notification);
					if(decision == "Connected"){
						this.movieNightService.sendNotification("friend_accept", notification.from, "", this.localStorageService.get(environment.user_id_storage), notification.link_id).subscribe((response) => {});
					}
				}
			})
		}

		public updateMovieRequest(notification, choice){
			if(choice == "Accept"){
				this.toolsService.showLoading('Accepting...').then(() => {
					this.movieNightService.updateMovieNight(notification.link_id, choice).subscribe((response) => {
						this.movieNightService.sendNotification("movie_night_confirm", notification.from, "", this.localStorageService.get(environment.user_id_storage), notification.link_id).subscribe((response) => {});
						this.router.navigateByUrl('/movie-nights/movie-night/' + notification.link_id);
						this.toolsService.hideLoading();
					})
				})
				//this.toastService.shortToast("Movie night added to calendar to see "+notification.movie_title+"!");
			} else {
				this.toolsService.showLoading('Declining...').then(() => {
					this.movieNightService.updateMovieNight(notification.link_id, choice).subscribe((response) => {
						this.toastService.shortToast("Declined.");
						this.movieNightService.sendNotification("movie_night_declined", notification.from, "", this.localStorageService.get(environment.user_id_storage), notification.link_id).subscribe((response) => {});
						this.toolsService.hideLoading();
					})
				})
			}

			this.dismissNotification(notification);
		}

		public goToVote(notification){
			this.dismissNotification(notification);
			this.router.navigateByUrl('/movie-votes/movie-vote-details/' + notification.link_id);
		}

		public goToActor(notification){
			this.dismissNotification(notification);
			this.router.navigateByUrl('/search/actor/' + notification.link_id + "?name=" + notification.actor_name);
		}

		public goToMyConnections(notification){
			this.dismissNotification(notification);
			this.router.navigateByUrl('/connections');
		}

		public goToMovieNight(notification){
			this.dismissNotification(notification);
			this.router.navigateByUrl('/movie-nights/movie-night/' + notification.link_id);
		}

		public dismissNotification(notification){
			this.notifications.splice(this.notifications.indexOf(notification), 1);
			this.movieNightService.markNotificationAsRead(notification.notification_id).subscribe((response) => {})
		}
	}
