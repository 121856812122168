import { Component, Input, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { MovieNightService } from '../../services/movie-night.service';
import { ToolsService } from '../../services/tools.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-movie-info-modal',
	templateUrl: 'movie-info.html'
})
export class MovieInfoModal implements OnInit {

	@Input() id: number;
	public movieInfo: any;
	public directors: any[] = [];
	public producers: any[] = [];
	public infoLoaded = false;
	public alertEligible = false;
	public userSubscribed = false;
	public showCast = false;
	public showShowtimes = false;
	public showTimeMovies: any[] = [];
	public showtimes: any[] = [];
	public theatersWithin10Miles: any[] = [];
	public showTimeResponse: any;
	public fandangoMovieId: string;
	public streaming_options_bool = {
		hulu: false,
		netflix: false,
		epix: false,
		hbogo: false,
		amazon: false,
		hbonow: false,
		fubo: false
	}

	constructor(private modalCtrl: ModalController, private movieNightService: MovieNightService,
		private toolsService: ToolsService, private alertController: AlertController, private localStorageService: LocalStorageService) { }

		ngOnInit() {
			this.toolsService.showLoading('Loading movie...').then(() => {
				this.movieNightService.getMovieAndSubDetails(this.id).subscribe((response) => {
					this.movieInfo = response;
					let theTime = new Date();
					let releaseDate = Date.parse(this.movieInfo.release_date);
					this.userSubscribed = response.subscribed;
					if(releaseDate > theTime.getTime()){
						this.alertEligible = true;
					}
					for(let stream of this.movieInfo.streaming_options){
						if(stream == "netflix"){
							this.streaming_options_bool.netflix = true;
						}

						if(stream == "hulu"){
							this.streaming_options_bool.hulu = true;
						}

						if(stream == "epix"){
							this.streaming_options_bool.epix = true;
						}

						if(stream == "amazon"){
							this.streaming_options_bool.amazon = true;
						}

						if(stream == "hbogo"){
							this.streaming_options_bool.hbogo = true;
						}

						if(stream == "hbonow"){
							this.streaming_options_bool.hbonow = true;
						}

						if(stream == "fubo"){
							this.streaming_options_bool.fubo = true;
						}
					}

					for(let member of response.casts.crew){
						if(member.job == "Director"){
							this.directors.push(member);
						}

						if(member.job == "Producer"){
							this.producers.push(member);
						}
					}

					console.log(this.movieInfo);
					this.infoLoaded = true;
					this.toolsService.hideLoading()
				});
			})
		}

		public checkShowtimesByZip(){
			if(this.localStorageService.get(environment.user_zip_code) != null && this.localStorageService.get(environment.user_zip_code) != ""){
				this.checkShowtimes();
			} else {
				this.getZipCode();
			}
		}

		public checkShowtimes(){
			let today = new Date();
			let todayString = today.getFullYear() + String(today.getMonth() + 1).padStart(2, '0') + String(today.getDate()).padStart(2, '0')
			this.toolsService.showLoading('Checking showtimes near you...').then(() => {
				this.movieNightService.getShowtimesByZip(this.localStorageService.get(environment.user_zip_code), todayString).subscribe((response) => {
					console.log("SHOWTIMES");
					this.showTimeResponse = response;
					console.log(this.showTimeResponse);

					Object.keys(response.movies).forEach((key) => {
						this.showTimeMovies.push(response.movies[key])
					})

					for(let movie of this.showTimeMovies){
						if(movie.title.toLowerCase().trim() == this.movieInfo.title.toLowerCase().trim()){
							console.log(movie);
							this.fandangoMovieId = movie.id;
							this.showShowtimes = true;
							for(let theaterGroup of response.theaterGroups){
								if(theaterGroup.code == "LT_MILES_10" || theaterGroup.code == "LT_MILES_5" || theaterGroup.code == "LT_MILES_3"){
									for(let theater of theaterGroup.theaters){
										let foundTheater = response.theaters[theater];
										let foundMovieShowtimes = [];
										for(let theaterMovie of foundTheater.movies){
											if(theaterMovie.id == this.fandangoMovieId){
												foundMovieShowtimes = theaterMovie.presentations;
											}
										}
										if(foundMovieShowtimes.length > 0){
											foundTheater.found_showtimes = foundMovieShowtimes;
											this.theatersWithin10Miles.push(foundTheater);
										}
									}
								}
							}
						}
					}

					if(this.fandangoMovieId){
						console.log("found theaters")
						console.log(this.theatersWithin10Miles);
					} else {
						console.log("no showtimes found")
						this.toolsService.showAlert("No results.", "No showtimes near you found for this movie.");
					}

					if(this.fandangoMovieId){
						for(let movie of response.movies){

						}
					}
					this.toolsService.hideLoading();
				});
			})
		}

		public toggleMovieSubscription(decision){
			this.toolsService.showLoading('Adding alert...').then(() => {
				this.movieNightService.toggleMovieSubscription(this.id, decision).subscribe((response) => {
					console.log(response);
					this.userSubscribed = (decision == "true");
					this.toolsService.hideLoading()
				});
			})
		}

		private async getZipCode() {
	    const alert = await this.alertController.create({
	      header: 'What is your zip code?',
	      inputs: [
	        {
	          name: 'zip',
	          type: 'text',
	          placeholder: 'Zip code...'
	        }
	      ],
	      buttons: [
	        {
	          text: 'Cancel',
	          role: 'cancel',
	          cssClass: 'secondary',
	          handler: () => {
	            console.log('Confirm Cancel');
	          }
	        }, {
	          text: 'Ok',
	          handler: (text) => {
							this.localStorageService.set(environment.user_zip_code, text.zip);
							this.checkShowtimesByZip();
	          }
	        }
	      ]
	    });

	    await alert.present();
	  }

		public closeModal()
		{
			this.modalCtrl.dismiss();
		}

	}
