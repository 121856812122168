import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { MovieInfoModal } from './movie-info-modal.component';

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		RouterModule
	],
	declarations: [MovieInfoModal],
	entryComponents: [
		MovieInfoModal
	]
})
export class MovieInfoModule {}
