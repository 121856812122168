import { Injectable } from '@angular/core';
import { LoadingController, AlertController } from '@ionic/angular';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';

@Injectable()
export class ToolsService {
	public loading: any;

	constructor(private loadingController: LoadingController,
		private socialSharing: SocialSharing,
		private calendar: Calendar,
		private alertController: AlertController
	) {}

	public async showLoading(message) {
		this.loading = await this.loadingController.create({
			message: message
		});
		return await this.loading.present();
	}

	public async hideLoading() {
		return await this.loading.dismiss();
	}

	public makeNameListForInvite(invites){
		var output = ""

		function sendInvite(element, index, array) {
			var first = (index == 0)
			var last = (array.length == (index+1))
			if(first)
			output += decodeURI(element.name)
			else if(last)
			output += " and "+decodeURI(element.name)+"."
			else
			output += ", "+decodeURI(element.name);
		}

		invites.forEach(sendInvite);

		return output;
	}

	public async showAlert(header, text) {
		const alert = await this.alertController.create({
			header: header,
			message: text,
			buttons: [
				{
					text: 'Ok.',
					role: 'cancel',
					cssClass: 'secondary',
					handler: (blah) => {

					}
				}
			]
		});

		await alert.present();
	}

	public addToCalendar(title, message, startDate, endDate){
		return this.calendar.createEvent(title, "", message, startDate, endDate)
	}

	public share(){
		this.socialSharing.share('Download Movie Night at https://www.themovienightapp.com so that we can find a good movie we BOTH want to see!', "Movie Night!");
	}

	public shareCustom(text){
		this.socialSharing.share(text);
	}

	public getDisplayDate(UNIX_timestamp){
		let a = new Date(UNIX_timestamp*1000);
		let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
		let year = a.getFullYear();
		let month = months[a.getMonth()];
		let date = a.getDate();
		let hour = a.getHours();
		let min = a.getMinutes();
		let sec = a.getSeconds();
		let time = month + ' ' + date+this.ordinal(date);
		return time;
	}

	public getDisplayDateAndYear(UNIX_timestamp){
		let a = new Date(UNIX_timestamp);
		let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
		let year = a.getFullYear();
		let month = months[a.getMonth()];
		let date = a.getDate();
		let hour = a.getHours();
		let min = a.getMinutes();
		let sec = a.getSeconds();
		let time = month + ' ' + date + ', ' + year;
		return time;
	}

	public getDisplayTime(time){
		let tempMin = time.getMinutes();
		let tempHours = time.getHours();
		let amPm = ""

		if(tempMin < 10)
		tempMin = "0"+tempMin;

		if(tempHours > 12)
		{
			tempHours = (tempHours - 12)
			amPm = "PM";
		}
		else
		{
			amPm = "AM";
		}

		return tempHours+":"+tempMin+" "+amPm;
	}

	public getCommentTime(date, server_time) {
		let t = date.split(/[- :]/);
		let serverTime = new Date(server_time*1000)
		console.log(serverTime);

		let tempDate = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
		// adding on MySQL timezone offset
		let timeDifference = ((serverTime.getTime() - (tempDate.getTime() + ((60*60*3+(60*5))*1000))))/1000;

		if(timeDifference < 0)
		{
			console.log(timeDifference);
			return "Just now";
		}
		else if(timeDifference < 60)
		{
			return Math.ceil(timeDifference) + " second(s) ago";
		}
		else if(timeDifference < (60*60))
		{
			return Math.ceil((timeDifference / (60))) + " minute(s) ago";
		}
		else if(timeDifference < (60*60*24))
		{
			return Math.ceil((timeDifference / (60*60))) + " hour(s) ago";
		}
		else
		{
			return this.getDisplayDateAndYear(tempDate.getTime());
		}
	}

	public checkEmail(email) {
		var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
		return re.test(email);
	}

	private ordinal(date) {
		return (date > 20 || date < 10) ? ([false, "st", "nd", "rd"])[(date%10)] || "th" : "th";
	}
}
