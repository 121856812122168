import { Component, Input, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MovieNightService } from '../../services/movie-night.service';
import { ToolsService } from '../../services/tools.service';
import { ToastService } from '../../services/toast.service';

@Component({
	selector: 'app-add-movies-playlist-modal',
	templateUrl: 'add-movies-playlist-modal.html'
})
export class AddMoviesPlaylistModal implements OnInit {
	@Input() id: number;
	public movies = [];
	public infoLoaded = false;
	private loading: any;

	constructor(private modalController: ModalController, private formBuilder: FormBuilder,
		private movieNightService: MovieNightService, private toastService: ToastService,
		private toolsService: ToolsService, private alertController: AlertController) { }

		ngOnInit() {
			this.toolsService.showLoading('Loading movies...').then(() => {
				this.movieNightService.getPossibleMoviesForPlaylist(this.id).subscribe((response) => {
					if(response.success){
						for(let movie of response.movies){
							let temp = movie._data;
							temp.isSelected = false;
							this.movies.push(temp);
						}
					}
					this.toolsService.hideLoading();
				})
			})
		}

		public updateMovie(movie, event){
			movie.isSelected = event.detail.checked;
		}

		public onSubmit() {
			let movie_ids_string = "";
			let first = true;
			for(let movie of this.movies){
				if(movie.isSelected){
					if(first)
					{
						movie_ids_string = movie_ids_string + movie.id;
						first = false;
					} else {
						movie_ids_string = movie_ids_string + "|" + movie.id;
					}
				}
			}

			if(movie_ids_string != ""){
				this.toolsService.showLoading('Adding movies...').then(() => {
					this.movieNightService.addMovieToPlaylist(movie_ids_string, this.id).subscribe((response) => {
						if(response.success){
							this.closeModal();
						} else {
							console.log("error")
						}
						this.toolsService.hideLoading();
						this.movieNightService.playlistUpdates.emit(movie_ids_string);
					})
				})
			} else {
				this.toolsService.showAlert('No movies', 'You must select movies to add first.');
			}
		}

		public closeModal() {
			this.modalController.dismiss();
		}
	}
