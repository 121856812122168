import { Component, OnInit } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { MembershipService, ToolsService, ModalService, FcmService } from '../services';
import { Router } from '@angular/router';

@Component({
	selector: 'app-login',
	templateUrl: 'login.page.html'
})
export class LoginPage implements OnInit {
	public email = '';
	public password = '';
	public username = '';
	public full_name = '';
	public loggingIn = true;
	public signingUUp = false;
	public isApp = false;

	constructor(private membershipService: MembershipService, private router: Router,
		private alertController: AlertController, private toolsService: ToolsService,
		private modalService: ModalService,
		private fcm: FcmService, private platform: Platform) {}

		ngOnInit() {
			if(this.platform.is('android') || this.platform.is('ios')){
				this.isApp = true;
			}
		}

		public login(){
			if(this.email != '' && this.password != ''){
				this.membershipService.login(this.email, this.password).subscribe((response) => {
					if(response.success){
						this.finishLogin(response);
					} else {
						this.toolsService.showAlert('Oops!', "Wrong username or password please try again.");
					}
				})
			} else {
				this.toolsService.showAlert('Oops!', "Please enter email and password");
			}
		}

		public signUp(){
			if(this.full_name.length < 2)
			{
				this.toolsService.showAlert('Oops!', 'Please enter your full name.');
				return;
			}

			if(this.password.length < 6)
			{
				this.toolsService.showAlert('Oops!', 'Please enter a password with more than 6 characters.');
				return;
			}

			if(!this.toolsService.checkEmail(this.email))
			{
				this.toolsService.showAlert('Oops!', 'Please enter a valid email address.');
				return;
			}

			if(this.username.length < 6 || (this.username.indexOf(' ') >= 0))
			{
				this.toolsService.showAlert('Oops!', 'Please enter a username with more than 6 characters, and no spaces.');
				return;
			}

			this.membershipService.checkUsername(this.username).subscribe((response) => {
				if(response.valid){
					this.membershipService.signUp(this.full_name, this.email, this.username, this.password).subscribe((response) => {
						;
						if(response.success){
							this.finishLogin(response);
						} else {
							this.toolsService.showAlert('Oops!', response.reason);
						}
					})
				} else {
					this.toolsService.showAlert('Oops!', 'Sorry, that username is already taken, please try a different one.');
				}
			})
		}

		public toggleType(){
			this.loggingIn = !this.loggingIn;
			this.signingUUp = !this.signingUUp;
		}

		private finishLogin(response){
			response.loggedIn = true;
			this.membershipService.setLoggedIn(response);
			this.membershipService.setUserId(response.id);
			this.membershipService.setUserPhoto(response.image);
			if(this.platform.is('android') || this.platform.is('ios')){
				this.membershipService.updateLastLogin();
			}
			this.membershipService.getUserSettings().subscribe((response) => {
				if(response.success){
					this.membershipService.setPublicProfile(response.public);
					this.membershipService.toggleAds(response.ads);
					if(this.platform.is('android') || this.platform.is('ios')){
						if(response.ads){
							// TODO Ads
						}
					}
				}
			})
			if(this.platform.is('android') || this.platform.is('ios')){
				this.fcm.getToken();
			}
			this.router.navigateByUrl('/movies/watch');
			if(this.membershipService.isFirstLogin()){
				this.modalService.helpModal();
				this.membershipService.loggedInFirstTime();
			}

		}
	}
