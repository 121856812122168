import { Component, OnInit } from '@angular/core';
import { AlertController, ActionSheetController } from '@ionic/angular';
import { MovieNightService, ToolsService, ModalService, ToastService, VoteService, PlaylistService } from '../services';
import { Router } from '@angular/router';

@Component({
	selector: 'app-news-feed',
	templateUrl: 'news-feed.page.html'
})
export class NewsFeedPage implements OnInit {
	public news: any[] = [];
	public slideOpts = {
		effect: 'flip'
	};

	constructor(private alertController: AlertController, private toolsService: ToolsService,
		private modalService: ModalService, private movieNightService: MovieNightService,
		private actionSheetController: ActionSheetController, private router: Router, private toastService: ToastService,
		private voteService: VoteService, private playlistService: PlaylistService) {}

		ngOnInit() {
			this.toolsService.showLoading('Loading...').then(() => {
				this.movieNightService.getNewsFeed("public").subscribe((response) => {
					if(response.success){
						for(let item of response.news){
							if(item.user_image){
								item.user_image = item.user_image.replace("http://", "https://");
							}
							
							if(item.connected_user_image){
								item.connected_user_image = item.connected_user_image.replace("http://", "https://");
							}
							
							if(item.type == "Movie Night")
							{
								item.user_name = decodeURI(item.user_name);
								item.night_date = this.toolsService.getDisplayDate(parseInt(item.night_date.split("|")[0]));
								item.friends_list = this.toolsService.makeNameListForInvite(item.attendees);
								this.news.push(item);
							}

							if(item.type == "Added Movie")
							{
								if(item.movies.length > 0)
								{
									let validMovies = [];
									for(let movie of item.movies){
										if(movie.backdrop != "null" || movie.poster != "null"){
											validMovies.push(movie)
										}
									}

									item.movies = validMovies;
									item.user_name = decodeURI(item.user_name);
									item.date_added = this.toolsService.getDisplayDate(parseInt(item.date_added));
									this.news.push(item);
								}
							}

							if(item.type == "New Connection")
							{
								if(item.connected_user_name != "")
								{
									item.user_name = decodeURI(item.user_name);
									item.connected_user_name = decodeURI(item.connected_user_name);
									item.date_added = this.toolsService.getDisplayDate(parseInt(item.date_added));
									this.news.push(item);
								}
							}

							if(item.type == "IMDB Import")
							{
								item.user_name = decodeURI(item.user_name);
								this.news.push(item);
							}

							if(item.type == "New Playlist")
							{
								item.user_name = decodeURI(item.user_name);
								item.date_added = this.toolsService.getDisplayDate(parseInt(item.date_added));
								this.news.push(item);
							}
						}
					}
					this.toolsService.hideLoading();
				})
			})
		}

		public async presentActionSheet(movie) {
			movie.id = movie.movie_id;
			movie.poster_path = movie.poster;
			movie.backdrop_path = movie.backdrop;
			movie.vote_average = movie.rating;
			let buttons = [{
				text: 'Add to playlist',
				icon: 'add',
				handler: () => {
					this.playlistService.addMovieToPlaylist(movie);
				}
			}, {
				text: 'Add to Vote',
				icon: 'bulb',
				handler: () => {
					this.voteService.addMovieToVote(movie);
				}
			}];

			if(!movie.added) {
				buttons.push({
					text: 'Add to watch list',
					icon: 'glasses',
					handler: () => {
						this.movieNightService.addMovie(movie, false).subscribe((response) => {
							movie.added = 1;
							this.toastService.shortToast(movie.original_title + " added to watch list.");
						})
					}
				})

				buttons.push({
					text: 'Add to seen list',
					icon: 'glasses',
					handler: () => {
						this.movieNightService.addMovie(movie, true).subscribe((response) => {
							movie.added = 1;
							this.toastService.shortToast(movie.original_title + " added to seen list.");
						})
					}
				})
			}
			const actionSheet = await this.actionSheetController.create({
				header: 'Movie Options',
				buttons: buttons
			});
			await actionSheet.present();
		}

		public goToUserProfile(user_id){
			this.router.navigateByUrl('/connections/user-profile/' + user_id + '/profile');
		}
	}
