import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingController, ActionSheetController, AlertController } from '@ionic/angular';
import { FriendsService, ToolsService, MovieNightService, ModalService,
	    ToastService, VoteService, MembershipService, PlaylistService } from '../../services';

@Component({
	selector: 'app-actor-profile',
	templateUrl: 'actor-profile.page.html'
})
export class ActorProfilePage {
	public actorId = '';
	public actorName = '';
	public profile: any = {};
	public movies: any[] =[];
	public actingMovies = [];
	public productionMovies = [];
	public writingMovies = [];
	public directingMovies = [];
	public showBio = false;
	public dataLoaded = false;
	public subscribed = false;
	public profile_pic = "https://www.themovienightapp.com/movie_night_uploads/no-image.jpg";
	public list_types = [
		{name:'Cast', value: "cast"},
		{name:'Writing', value: "writing"},
		{name:'Production', value: "production"},
		{name:'Directing', value: "directing"}
	];
	public list_type = "cast";

	constructor(private route: ActivatedRoute, private toolsService: ToolsService,
		private friendsService: FriendsService, public loadingController: LoadingController,
		private movieNightService: MovieNightService, private modalService: ModalService,
		private actionSheetController: ActionSheetController, private toastService: ToastService,
		private voteService: VoteService, private alertController: AlertController,
		private membershipService: MembershipService, private playlistService: PlaylistService ) {}

		ngOnInit(): void {
			this.actorId = this.route.snapshot.paramMap.get('id');
			this.route.queryParams.subscribe(params => {
				this.actorName = params['name'];
			});
			this.toolsService.showLoading('Loading profile...').then(() => {
				this.movieNightService.getActorProfile(this.actorId).subscribe((response) => {
					if(response.success){
						this.profile = response;
						this.subscribed = response.subscribed;
						this.actingMovies = response.movies;

						for(let movie of response.crew_movies){
							if(movie.department == "Writing"){
								this.writingMovies.push(movie)
							} else if(movie.department == "Production"){
								this.productionMovies.push(movie)
							} else if(movie.department == "Directing"){
								this.directingMovies.push(movie)
							}
						}

						this.actingMovies.sort((a, b) => (a.release_date < b.release_date) ? 1 : -1);
						this.productionMovies.sort((a, b) => (a.release_date < b.release_date) ? 1 : -1);
						this.writingMovies.sort((a, b) => (a.release_date < b.release_date) ? 1 : -1);
						this.directingMovies.sort((a, b) => (a.release_date < b.release_date) ? 1 : -1);
						if(response.profile_path != null){
							this.profile_pic = "https://image.tmdb.org/t/p/w154"+response.profile_path;
						}

						this.movies = this.actingMovies;

						if(response.known_for != null){
							if(response.known_for == "Writing"){
								this.list_type = "writing";
								this.movies = this.writingMovies;
							} else if(response.known_for == "Directing"){
								this.list_type = "directing";
								this.movies = this.directingMovies;
							} else if(response.known_for == "Production"){
								this.list_type = "production";
								this.movies = this.productionMovies;
							}
						}
					}
					this.dataLoaded = true;
					this.toolsService.hideLoading();
				})
			})
		}

		public async toggleSubscription(){
			let header = "";
			let message = "";
			if(this.subscribed){
				header = 'Unsubscribe?';
				message = "You will no longer receive alerts when this actor has a new movie on the way.";
			} else {
				header = 'Subscribe?';
				message = "You will be notified when this actor has a new movie in development!";
			}

			const alert = await this.alertController.create({
				header: header,
				message: message,
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							console.log('Confirm Cancel');
						}
					}, {
						text: 'Confirm',
						handler: () => {
							this.toolsService.showLoading('Updating...').then(() => {
								this.membershipService.toggleSubscription(this.actorId, this.subscribed ? "false" : "true").subscribe((response) => {
									if(response.success){
										if(this.subscribed){
											console.log("unsubscribed");
											this.subscribed = false;
										} else {
											console.log("subscribed");
											this.subscribed = true;
										}
									}
									this.toolsService.hideLoading();
								})
							})
						}
					}
				]
			});

			await alert.present();
		}

		public addMovie(movie){
			movie.already_added = true;
			this.toastService.shortToast(movie.original_title + " added to list.");
		}

		public deleteMovie(movie){
			movie.already_added = false;
			this.toastService.shortToast(movie.original_title + " was deleted.");
		}

		public toggleBio(){
			this.showBio = !this.showBio;
		}

		public changeList(){
			if(this.list_type == "cast"){
				this.movies = this.actingMovies;
			} else if(this.list_type == "production") {
				this.movies = this.productionMovies;
			} else if(this.list_type == "writing") {
				this.movies = this.writingMovies;
			} else {
				this.movies = this.directingMovies
			}
		}
	}
