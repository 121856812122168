import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { MovieNightService, ToolsService, ModalService } from '../../services';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-movie-night',
	templateUrl: 'movie-night.page.html'
})
export class MovieNightPage implements OnInit {
	public movieNight: any;
	public attendees = [];
	public pending_attendees = [];
	public comments = [];
	public loaded = false;
	public commentError = false;
	public commentBody = '';
	private movieNightId = '';

	constructor(private route: ActivatedRoute, private movieNightService: MovieNightService,
							private toolsService: ToolsService, public modalService: ModalService, private router: Router,
						  private localStorageService: LocalStorageService) {}

		ngOnInit(): void {
			this.toolsService.showLoading('Loading movie night...').then(() => {
				this.movieNightId = this.route.snapshot.paramMap.get('id');
				this.movieNightService.getMovieNight(this.movieNightId).subscribe((response) => {
					console.log(response.movie_night)
					if(typeof response.movie_night != 'undefined')
					{
						this.movieNight = response.movie_night;
						this.movieNight.date = this.toolsService.getDisplayDate(parseInt(response.movie_night.date_scheduled.split("|")[0]))
						this.movieNight.isOwner = this.localStorageService.get(environment.user_id_storage)  == response.movie_night.user_requested_id;

						var rawTime = response.movie_night.date_scheduled.split("|")[1];

						var d = new Date();
						d.setHours(rawTime.split(":")[0]);
						d.setMinutes(rawTime.split(":")[1]);

						this.movieNight.time = this.toolsService.getDisplayTime(d);
					}

					if(response.movie_night.attendees.length > 0)
					{
						for(let attendee of response.movie_night.attendees) {
							if(attendee.image)
							{
								attendee.image = attendee.image.replace('http://', 'https://');
							}
							if(attendee.user_id == "CC33D2E6-C601-4346-AFCA-8AE7B19781C2") {
								this.attendees.unshift(attendee);
							}
							else if(attendee.decision == "Accept"){
								this.attendees.push(attendee);
							}
							else
							{
								this.pending_attendees.push(attendee);
							}
						}
					}

					if(response.movie_night.comments.length > 0)
					{
						for(let comment of response.movie_night.comments) {
							comment.name = decodeURI(comment.name);
							comment.date_added = this.toolsService.getCommentTime(comment.date_added, response.server_time);
							comment.image = comment.image.replace('http://', 'https://');

							this.comments.push(comment);
						}
					}

					this.toolsService.hideLoading();
					this.loaded = true;
				}, (error) => {
					alert("Sorry something went wrong pleaes try again.");
					this.router.navigateByUrl('/movie-nights');
					this.toolsService.hideLoading();
				});
			})
		}

		public submitComment(){
			if(this.commentBody != "")
			{
				this.movieNightService.addComment(this.movieNight.id, this.commentBody).subscribe((response) => {
					if(response.success){
						response.comment.name = decodeURI(response.comment.name);
						response.comment.date_added = this.toolsService.getCommentTime(response.comment.date_added, response.server_time);
						this.comments.push(response.comment);
						this.commentBody = "";
					}
					this.commentError = false;
				});
			}
			else
			this.commentError = true;
		}
	}
