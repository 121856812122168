import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class FriendsService {
	private baseApi = environment.movie_night_endpoint;

	constructor(private http: Http, private localStorageService: LocalStorageService) {}

	public getConnections(){
		return this.http.get(this.baseApi + "get_connections_list.php", { search: { u: this.localStorageService.get(environment.user_id_storage) } }).map((res: Response) => res.json())
	}

	public getConnectionMovies(id){
		let dataParams = {
			u: id,
			sort: "Movie Title",
			order: "ASC",
			compare: this.localStorageService.get(environment.user_id_storage)
		}
		return this.http.get(this.baseApi + "get_user_movie_list.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public getConnectionStats(id){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			f: id
		}
		return this.http.get(this.baseApi + "get_friend_stats.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public searchFriends(query){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			query: query
		}
		return this.http.get(this.baseApi + "search_for_friends.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public updateConnectionRequest(user_id, cid, value, related){
		let dataParams = {
			u: user_id,
			cid: cid,
			value: value,
			related: related
		}

		return this.http.get(this.baseApi + "update_connection.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public requestConnection(friend_id, friend_member_id){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			f: friend_id,
			f_user_id: friend_member_id
		}
		return this.http.get(this.baseApi + "request_connection.php", { search: dataParams }).map((res: Response) => res.json())
	}
}
