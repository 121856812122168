import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-maintenence',
	templateUrl: 'maintenence.page.html'
})
export class MaintenencePage implements OnInit {

	constructor() {}

	ngOnInit() {

	}
}
