import { Component } from '@angular/core';
import { MembershipService, ModalService, ToolsService } from '../services';

@Component({
	selector: 'app-my-account',
	templateUrl: 'my-account.page.html'
})
export class MyAccountPage {
	constructor(private membershipService: MembershipService, public modalService: ModalService,
		public toolsService: ToolsService) {}

		public logout(){
			this.membershipService.logout();
		}
	}
