import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MovieNightService, ToastService, FriendsService, ToolsService } from '../services';
import { ActionSheetController, AlertController } from '@ionic/angular';
import { LocalStorageService } from 'angular-2-local-storage';

@Component({
	selector: 'app-find-friends',
	templateUrl: 'find-friends.page.html'
})
export class FindFriendsPage implements OnInit {
	public searchTerm = '';
	public friends = [];

	constructor(private movieNightService: MovieNightService, public toolsService: ToolsService,
		private router: Router, public toastService: ToastService,
		private friendsService: FriendsService, private localStorageService: LocalStorageService,
		private alertController: AlertController) {}

		ngOnInit() {

		}

		public search(input){
			if(this.searchTerm != "" && input.keyCode == 13){
				this.friends = [];
				this.toolsService.showLoading('Searching...').then(() => {
					this.friendsService.searchFriends(this.searchTerm).subscribe((response) => {
						
						if(response.success){
							for(let friend of response.friends){
								friend.name = decodeURI(friend.name);
								if(friend.image.indexOf("https") === -1)
									friend.image = friend.image.replace("http", "https")
								this.friends.push(friend);
							}
							console.log("friends", this.friends);
						}
						this.toolsService.hideLoading();
					})
				})
			}
		}

		public createConnectionRequest(friend){
			this.friendsService.requestConnection(friend.id, friend.member_id).subscribe((response) => {
				if(response.success){
					if(typeof response.fbid !== 'undefined'){
						this.movieNightService.sendNotification("new_connection_request", "", response.fbid, "", "").subscribe((response) => {});
					}
					else{
						this.movieNightService.sendNotification("new_connection_request", response.user_id, "", "", "").subscribe((response) => {});
					}

					friend.friend_status = "Pending";
				}
			})
		}

		public async deleteConnection(friend){
			const alert = await this.alertController.create({
				header: 'Delete friend?',
				message: 'Are you sure you want to delete this friend?',
				buttons: [
					{
						text: 'Delete',
						cssClass: 'primary',
						handler: () => {
							this.toolsService.showLoading('Deleting...').then(() => {
								this.friendsService.updateConnectionRequest(friend.friend_user_id, friend.connection_id, "Delete", "true").subscribe((response) => {
									if(response.success){
										friend.friend_status = "";
									}
									this.toolsService.hideLoading();
								})
							})
						}
					},
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: (blah) => {

						}
					}
				]
			});

			await alert.present();
		}
	}
