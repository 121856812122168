import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { HelpModal } from './help-modal.component';

@NgModule({
	imports: [
		CommonModule,
		IonicModule
	],
	declarations: [HelpModal],
	entryComponents: [
		HelpModal
	]
})
export class HelpModalModule {}
