import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyMovies } from './my-movies.page';
import { AuthGuard } from '../services/authguard.service';
import { MaintenenceGaurd } from '../services/maintenence-gaurd.service';

const routes: Routes = [
	{
		path: 'movies',
		component: MyMovies,
		children: [
			{
				path: 'watch',
				canActivate: [AuthGuard, MaintenenceGaurd],
				children: [
					{
						path: '',
						loadChildren: './movie-tab/movie-tab.module#MovieTabModule'
					}
				]
			},
			{
				path: 'seen',
				canActivate: [AuthGuard, MaintenenceGaurd],
				children: [
					{
						path: '',
						loadChildren: './movie-tab/movie-tab.module#MovieTabModule'
					}
				]
			},
			{
				path: 'playlists',
				canActivate: [AuthGuard, MaintenenceGaurd],
				children: [
					{
						path: '',
						loadChildren: './playlists/playlists.module#PlaylistsModule'
					}
				]
			},
			{
				path: '',
				canActivate: [AuthGuard, MaintenenceGaurd],
				redirectTo: '/movies/watch',
				pathMatch: 'full'
			}
		]
	},
	{
		path: '',
		canActivate: [AuthGuard, MaintenenceGaurd],
		redirectTo: '/movies/watch',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MyMoviesRoutingModule { }
