import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActionSheetController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { MovieNightService, ToastService, ToolsService, ModalService, MembershipService, VoteService, PlaylistService } from '../../services';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-movie',
	templateUrl: 'movie.component.html'
})
export class Movie {
	@Input() movie: any;
	@Input() movieType: string;
	@Output() onDelete: EventEmitter<any> = new EventEmitter();
	@Output() onChange: EventEmitter<any> = new EventEmitter();
	@Output() onAdd: EventEmitter<any> = new EventEmitter();

	constructor(private router: Router, private actionSheetController: ActionSheetController,
		private movieNightService: MovieNightService, private toastService: ToastService,
		private toolsService: ToolsService, public modalService: ModalService,
		private membershipService: MembershipService,
		private voteService: VoteService, private playlistService: PlaylistService) {
	}

		public async presentActionSheet(movie) {
			let buttons = [
				{
					text: 'Add to playlist',
					icon: 'add',
					handler: () => {
						this.playlistService.addMovieToPlaylist(movie);
					}
				}
			]

			if(typeof this.movieType !== "undefined"){
				buttons.push({
					text: 'Mark as ' + (this.movieType == "watch" ? "Seen" : "Not seen"),
					icon: 'glasses',
					handler: () => {
						this.movieNightService.updateMovieStatus(movie.id, this.movieType == "watch" ? true : false).subscribe((response) => {
							this.onChange.emit("movie changed");
						});
					}
				})
			}

			buttons.push({
				text: 'Add to Vote',
				icon: 'bulb',
				handler: () => {
					this.voteService.addMovieToVote(movie);
				}
			})

			if(typeof movie.already_added !== "undefined" && !movie.already_added) {
				buttons.push({
					text: 'Add to watch list',
					icon: 'glasses',
					handler: () => {
						this.movieNightService.addMovie(movie, false).subscribe((response) => {
							this.onAdd.emit("movie added");
						})
					}
				})

				buttons.push({
					text: 'Add to seen list',
					icon: 'glasses',
					handler: () => {
						this.movieNightService.addMovie(movie, true).subscribe((response) => {
							this.onAdd.emit("movie added");
						})
					}
				})
			}

			if(typeof movie.already_added === "undefined" || (typeof movie.already_added !== "undefined" && movie.already_added)) {
				buttons.push({
					text: 'Delete',
					icon: 'trash',
					handler: () => {
						this.movieNightService.deleteMovie(movie.id).subscribe((response) => {
							this.onDelete.emit("movie deleted");
						});
					}
				})
			}

			buttons.push({
				text: 'Get recommendations',
				icon: 'information-circle',
				handler: () => {
					this.router.navigateByUrl('/recommendations/'+movie.id);
				}
			})

			buttons.push({
				text: 'Cancel',
				icon: 'close',
				handler: () => {

				}
			})

			const actionSheet = await this.actionSheetController.create({
				header: 'Movie Options',
				buttons: buttons
			});
			await actionSheet.present();
		}
	}
