// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  movie_night_endpoint: "https://www.themovienightapp.com/REST2/",
  user_id_storage: "USER_ID",
  user_photo_storage: "USER_PHOTO",
  user_logged_in_storage: "USER_LOGGED_IN",
  maintenence_mode_storage: "MAINTENANCE",
  public_profile_setting: "PUBLIC_PROFILE",
  user_zip_code: "USER_ZIP_CODE",
  dark_mode: "DARK_MODE",
  show_ads: "SHOW_ADS",
  show_import_setting: "SHOW_IMPORT",
  show_rate_reminder: "SHOW_RATING_REMINDER",
  show_ad_reminder: "SHOW_AD_REMINDER",
  periodic_checks_timer: 200000
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
