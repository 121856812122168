import { Component, OnInit } from '@angular/core';
import { FriendsService, ToolsService } from '../services';
import { LoadingController } from '@ionic/angular';

@Component({
	selector: 'app-connections',
	templateUrl: 'connections.page.html'
})
export class ConnectionsPage implements OnInit {
	public friends = [];
	public friendsLoaded = false;

	constructor(private friendsService: FriendsService, private toolsService: ToolsService) {}

	ngOnInit() {
		this.toolsService.showLoading('Loading friends...').then(() => {
			this.friendsService.getConnections().subscribe((response) => {
				for(let friend of response.connections){
					friend.name = decodeURI(friend.name);
					if(friend.image.indexOf("https") === -1)
						friend.image = friend.image.replace("http", "https")
					this.friends.push(friend);
				}
				this.friendsLoaded = true;
				this.toolsService.hideLoading();
			})
		})
	}

	public promptDelete(){
		// TODO: delete friend flow when ionic fixes the (press) event....
	}
}
