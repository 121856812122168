import { Component, OnInit } from '@angular/core';
import { MovieNightService, ToolsService } from '../services';
import { LoadingController, AlertController, ActionSheetController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
	selector: 'app-my-movies-nights',
	templateUrl: 'my-movie-nights.page.html'
})
export class MyMovieNights implements OnInit {
	public movieListLoaded = false;
	public movieNights = [];
	public listLimit = 10;

	constructor(public loadingController: LoadingController, private toolsService: ToolsService,
		private movieNightService: MovieNightService, private alertController: AlertController,
		private router: Router, private actionSheetController: ActionSheetController) {}

		ngOnInit() {
			this.movieNights = [];
			this.movieListLoaded = false;
			this.toolsService.showLoading('Loading movie nights...').then(() => {
				this.movieNightService.getAllMovieNights().subscribe((response) => {
					let theDate = new Date();
					let theDateUTC = theDate.getTime()/1000;

					for(let movieNight of response.movie_nights){
						if(movieNight.original_title != "")
						{
							movieNight.date = this.toolsService.getDisplayDate(parseInt(movieNight.date_scheduled.split("|")[0]))
							movieNight.upcoming = movieNight.date_scheduled.split("|")[0] > theDateUTC;

							var rawTime = movieNight.date_scheduled.split("|")[1];

							var d = new Date();
							d.setHours(rawTime.split(":")[0]);
							d.setMinutes(rawTime.split(":")[1]);

							movieNight.time = this.toolsService.getDisplayTime(d);
							this.movieNights.push(movieNight);
						}
					}

					this.movieNights.sort(this.sortNights);
					this.movieListLoaded = true;
					this.toolsService.hideLoading()
				}, (error) => {
					alert("Sorry something went wrong pleaes try again.");
					this.router.navigateByUrl('/');
					this.toolsService.hideLoading();
				});
			})
		}

		public async presentActionSheet(movieNight) {
			let buttons = [
				{
					text: 'Delete',
					icon: 'trash',
					handler: () => {
						this.markAsWatched(movieNight);
					}
				}
			]

			const actionSheet = await this.actionSheetController.create({
				header: 'Movie Night Options',
				buttons: buttons
			});
			await actionSheet.present();
		}

		public shareMovieNight(movie_night){
			this.toolsService.shareCustom("Movie Night - "+movie_night.original_title);
		}

		public async markAsWatched(movie_night){

			const alert = await this.alertController.create({
				header: 'Careful!',
				message: 'Remove this movie from your movie night list?',
				buttons: [
					{
						text: 'Delete',
						cssClass: 'primary',
						handler: () => {
							this.toolsService.showLoading('Deleting...').then(() => {
								this.movieNightService.markMovieNightDelete(movie_night.id).subscribe((response) => {
									this.toolsService.hideLoading();
									if(response.success){
										this.movieNights.splice(this.movieNights.indexOf(movie_night), 1);
										this.ngOnInit();
									}
								})
							})
						}
					},
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: (blah) => {

						}
					}
				]
			});

			await alert.present();
		}

		public updateListLimit(event){
			setTimeout(() => {
				this.listLimit += 10;
				event.target.complete();
			}, 100)
		}

		private sortNights(a,b) {
			if (a.date_scheduled.split("|")[0] > b.date_scheduled.split("|")[0])
				return -1;
			else if (a.date_scheduled.split("|")[0] < b.date_scheduled.split("|")[0])
				return 1;
			else
				return 0;
		}
	}
