import { Component, Input, OnInit } from '@angular/core';
import { NavController, ModalController, AlertController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Response, Http, RequestOptions, Headers } from '@angular/http';
import { MovieNightService } from '../../services/movie-night.service';
import { ToolsService } from '../../services/tools.service';
import { ToastService } from '../../services/toast.service';
import { LoadingController } from '@ionic/angular';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/forkJoin';
import * as moment from 'moment';

@Component({
	selector: 'app-schedule-movie-night-modal',
	templateUrl: 'schedule-movie-night.html'
})
export class ScheduleMovieNight implements OnInit {

	@Input() id: number;
	@Input() title: string;
	@Input() movie_night_id: string;
	public movieInfo: any;
	public friends = [];
	public scheduleForm: FormGroup;
	public infoLoaded = false;
	public submitted = false;
	public friendSearch = {name: ''};
	public invitedNames = "Myself"
	public minimumDate = "";
	public minimumTime = "";
	private loading: any;

	constructor(private nav: NavController, private modalCtrl: ModalController, private http: Http,
		public loadingController: LoadingController, private formBuilder: FormBuilder,
		public alertController: AlertController, private movieNightService: MovieNightService,
		private toolsService: ToolsService, private toastService: ToastService) { }

		ngOnInit() {
			let today = new Date();
			let dd = today.getDate().toString();
			let mm = (today.getMonth() + 1).toString();
			let minutes = today.getMinutes().toString();
			let hours = today.getHours().toString();
			let yyyy = today.getFullYear();

			if (parseInt(dd, 10) < 10) {
				dd = '0' + dd;
			}

			if (parseInt(mm, 10) < 10) {
				mm = '0' + mm;
			}

			// if (parseInt(minutes, 10) < 10) {
			// 	minutes = '0' + minutes;
			// }
			//
			// if (parseInt(hours, 10) < 10) {
			// 	hours = '0' + hours;
			// }

			this.minimumDate = yyyy + '-' + mm + '-' + dd;
			//this.minimumTime = this.minimumDate+"T"+hours+":"+minutes;
			this.scheduleForm = this.formBuilder.group({
				date: ['', Validators.required],
				time: ['', Validators.required],
				location: [''],
				message: ['']
			});

			this.toolsService.showLoading('Loading...').then(() => {
				this.movieNightService.getMovieDetails(this.id).subscribe((response) => {
					this.movieInfo = response;
					this.infoLoaded = true;
					this.toolsService.hideLoading()
				});
			})

			this.movieNightService.getNotInvitedFriends("").subscribe((response) => {
				for(let connection of response.connections){
					connection.name = decodeURI(connection.name);
					if(connection.image.indexOf("https") === -1)
						connection.image = connection.image.replace("http", "https")
					connection.selected = false;
					this.friends.push(connection)
				}
			});

			if(this.movie_night_id){
					this.movieNightService.getMovieNight(this.movie_night_id).subscribe((response) => {
						console.log(response.movie_night)
						if(typeof response.movie_night != 'undefined')
						{
							let movieNight = response.movie_night;
							movieNight.date = response.movie_night.date_scheduled.split("|")[0] * 1000;
							let formattedDate = moment(movieNight.date);

							var rawTime = movieNight.date_scheduled.split("|")[1];

							var d = new Date();
							d.setHours(rawTime.split(":")[0]);
							d.setMinutes(rawTime.split(":")[1]);

							movieNight.time = this.toolsService.getDisplayTime(d);

							let timeAndDate = moment(formattedDate.format("MM/DD/YYYY") + ' ' + rawTime);

							this.scheduleForm.setValue({
								date: timeAndDate.format("YYYY-MM-DDTHH:mm:ss"),
								time: timeAndDate.format("YYYY-MM-DDTHH:mm:ss"),
								location: movieNight.location,
								message: ''
							});
						}
					});
			}
		}

		public async presentAlertConfirm() {
			const alert = await this.alertController.create({
				header: 'Schedule Movie Night!',
				message: this.formSendToMessage(),
				buttons: [
					{
						text: 'Yes!',
						cssClass: 'primary',
						handler: () => {
							this.scheduleMovieNight();
						}
					},
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: (blah) => {

						}
					}
				]
			});

			await alert.present();
		}

		public get f() { return this.scheduleForm.controls; }

		public changeFriendStatus(friend, checked){
			friend.selected = checked;
			this.setInvitedFriendsText();
		}

		public setInvitedFriendsText(){
			this.invitedNames = "Myself";

			for(let friend of this.friends){
				if(friend.selected){
					this.invitedNames += ", " + friend.name;
				}
			}
		}

		public onSubmit() {

			if(this.movie_night_id){
				this.editMovieNight();
			}
			else {
				if (this.scheduleForm.invalid) {
					return;
				}
				else {
					this.presentAlertConfirm();
				}
			}
		}

		private formSendToMessage(){
			let output = "";
			let first = true;
			let numberFriends = 0;


			for(let friend of this.friends){
				if(friend.selected){
					numberFriends++;
					if(first){
						output = friend.name;
						first = false;
					} else {
						output += ", " + friend.name
					}
				}
			}

			if(numberFriends == 0){
				output = "Schedule this movie night for yourself?";
			} else {
				output = 'Would you like to send a movie night request to ' + output + "?";
			}

			return output;
		}

		private inviteFriends(movie_night_id){
			let friendsToInvite = [];
			for(let friend of this.friends) {
				if(friend.selected) {
					friendsToInvite.push(friend)
				}
			}

			if(friendsToInvite.length == 0){
				this.toolsService.hideLoading();
				this.closeModal();
				return;
			}

			let allInvites = Observable.forkJoin(
				friendsToInvite.map(friend => this.movieNightService.addAttendee(friend.id, movie_night_id))
			);

			let allNotifications = Observable.forkJoin(
				friendsToInvite.map(friend => this.movieNightService.sendNotification("movie_night", friend.id, "", "", ""))
			);

			allInvites.subscribe((result => {
				allNotifications.subscribe((result => {
					this.toastService.shortToast("Movie night created!")
					this.toolsService.hideLoading();
					this.closeModal();
				}))
			}))
		}

		private scheduleMovieNight(){
			this.toolsService.showLoading('Sending...');
			this.movieNightService.requestMovieNight(this.id, ((new Date(this.f.date.value).getTime() / 1000)), parseInt(this.f.time.value.split("T")[1].split(":")[0], 10),
			parseInt(this.f.time.value.split("T")[1].split(":")[1], 10), encodeURI(this.f.message.value), encodeURI(this.f.location.value))
			.subscribe((response) => {
				if(response.success){
					this.inviteFriends(response.movie_night_id);
				}
			});
		}

		private editMovieNight(){
			this.toolsService.showLoading('Editing...').then(() => {
				console.log("TIME")
				console.log(this.f.time.value)
				this.movieNightService.editMovieNight(this.movie_night_id, ((new Date(this.f.date.value).getTime() / 1000)), parseInt(this.f.time.value.split("T")[1].split(":")[0], 10),
				parseInt(this.f.time.value.split("T")[1].split(":")[1], 10), encodeURI(this.f.location.value))
				.subscribe((response) => {
					this.toolsService.hideLoading();
					this.closeModal();
				});
			})
		}

		public closeModal()
		{
			this.modalCtrl.dismiss();
		}
	}
