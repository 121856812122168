import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { MovieNightService, ToolsService, ModalService, VoteService } from '../../services';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-movie-vote-details',
	templateUrl: 'movie-vote-details.page.html'
})
export class MovieVoteDetailsPage {
	public movieNight: any;
	public dataLoaded = false;
	public movies = [];
	public voters = [];
	public voteTitle = '';
	public status = '';
	public displayStatus = '';
	public voteId = '';
	public isOwner = true;
	public ownerId = '';
	public userVoteStatus = "Invited";
	public userVote = "";
	public winningMovie = "";
	public movieLoaded = false;
	public highestVoteCount = 0;
	public winningMovieDetails: any = {};

	constructor(private route: ActivatedRoute, private movieNightService: MovieNightService,
		private toolsService: ToolsService, public modalService: ModalService, private router: Router,
		public voteService: VoteService, private localStorageService: LocalStorageService) {
			if (voteService.votedNotify.subscribe != null) {
				voteService.votedNotify.subscribe(vote => this.onVotedChange(vote));
			}
			if (voteService.votedUserAddedNotify.subscribe != null) {
				voteService.votedUserAddedNotify.subscribe(vote => this.onUserAddedChange(vote));
			}
			if (voteService.votedMovieAddedNotify.subscribe != null) {
				voteService.votedMovieAddedNotify.subscribe(vote => this.onMovieAddedChange(vote));
			}
		}

		ngOnInit(): void {
			this.getVoteDetails(false);
		}

		public getVoteDetails(emit){
			this.voters = [];
			this.movies = [];
			this.dataLoaded = false;
			if(!emit){
				this.toolsService.showLoading('Loading vote...').then(() => {
					this.getMovieDetailsData();
				})
			} else {
				this.getMovieDetailsData();
			}
		}

		public getMovieDetailsData(){
			this.voteId = this.route.snapshot.paramMap.get('id');
			this.voteService.getVoteDetails(this.voteId).subscribe((response) => {
				console.log(response);
				if(response.success){
					this.voteTitle = response.title;
					this.status = response.status;
					this.ownerId = response.owner_id;
					this.winningMovie = response.winning_movie_id;
					if(this.winningMovie != ""){
						this.movieNightService.getMovieDetails(this.winningMovie).subscribe((response) => {
							this.winningMovieDetails = response;
							this.movieLoaded = true;
							console.log(this.winningMovieDetails);
						})
					}
					this.setStatus();
					for(let movie of response.movies){
						movie.numberOfVotes = this.getMovieVotes(response.voters, movie.id);
						this.movies.push(movie);
					}
					console.log(this.movies)
					this.movies = response.movies;
					this.isOwner = response.owner_id == this.localStorageService.get(environment.user_id_storage);
					for(let voter of response.voters){
						if(voter.id != response.owner_id || (voter.id == response.owner_id && voter.status == "Voted")){
							voter.name = decodeURI(voter.name);
							this.voters.push(voter);
							if(voter.id == this.localStorageService.get(environment.user_id_storage)){
								this.userVoteStatus = voter.status;
								this.userVote = voter.selected_movie;
							}
						}
					}
					this.getHighestVoteCount();
				}
				this.dataLoaded = true;
				this.toolsService.hideLoading();
			})
		}

		public getMovieVotes(voters, movieId){
			let count = 0;
			for(let voter of voters){
				if(voter.selected_movie == movieId)
					count++;
			}
			return count;
		}

		public setStatus(){
			if(this.status == "New"){
				this.displayStatus = "Setup";
			} else if(this.status == "Voting") {
				this.displayStatus = "In progress";
			} else if(this.status == "Final") {
				this.displayStatus = "Voting Completed";
			} else if(this.status == "Tied") {
				this.displayStatus = "Tied Vote";
			} else {
				this.displayStatus = "Unknown";
			}
		}

		public finalize(){
			this.toolsService.showLoading('Starting vote...').then(() => {
				this.voteService.updateVote(this.voteId, 'Voting').subscribe((response) => {
					console.log(response);
					if(response.success){
						this.status = "Voting";
						this.setStatus();
					}
					this.toolsService.hideLoading();
				})
			})
		}

		public scheduleMovieNight(){
			this.toolsService.showLoading('Loading...').then(() => {
				this.movieNightService.getMovieDetails(this.winningMovie).subscribe((response) => {
					console.log(response)
					if(response.id){
						this.modalService.scheduleModal(this.winningMovie, response.title, null);
					}
					this.toolsService.hideLoading()
				});
			});
		}

		private getHighestVoteCount(){
			for(let movie of this.movies){
				if(movie.numberOfVotes > this.highestVoteCount){
					this.highestVoteCount = movie.numberOfVotes;
				}
			}
		}

		private onVotedChange(vote){
			if(vote.vote_id == this.voteId){
				this.getVoteDetails(true);
			}
		}

		private onUserAddedChange(vote){
			if(vote.vote_id == this.voteId){
				this.getVoteDetails(true);
			}
		}

		private onMovieAddedChange(vote){
			if(vote.vote_id == this.voteId){
				this.getVoteDetails(true);
			}
		}
	}
