import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { MovieInfoModule } from '../modals/movie-info-modal/movie-info-modal.module';
import { ScheduleMovieNightModule } from '../modals/schedule-movie-night/schedule-movie-night.module';
import { PipeModule } from '../pipes/pipes.module';

import { Movie } from './movie/movie.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		RouterModule,
		HttpModule,
		MovieInfoModule,
		ScheduleMovieNightModule,
		PipeModule
	],
	declarations: [Movie],
	exports: [Movie]
})
export class ComponentsModule {}
