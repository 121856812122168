import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class SearchService {
	private baseApi = environment.movie_night_endpoint;

	constructor(private http: Http, private localStorageService: LocalStorageService) {}

	public searchMovies(search){
		return this.http.get(this.baseApi + "search_movies.php", { search: { u: this.localStorageService.get(environment.user_id_storage), s: search } }).map((res: Response) => res.json())
	}

	public searchActors(search){
		return this.http.get(this.baseApi + "search_actors.php", { search: { u: this.localStorageService.get(environment.user_id_storage), s: search } }).map((res: Response) => res.json())
	}

	public searchMoviesAndActors(search){
		return this.http.get(this.baseApi + "search_movies_and_actors.php", { search: { u: this.localStorageService.get(environment.user_id_storage), s: search } }).map((res: Response) => res.json())
	}

	public searchList(list){
		return this.http.get(this.baseApi + "search_movie_filters.php", { search: { u: this.localStorageService.get(environment.user_id_storage), list: list } }).map((res: Response) => res.json())
	}
}
