import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { LocalStorageService } from 'angular-2-local-storage';
import { AlertController, LoadingController } from '@ionic/angular';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class VoteService {
	public votedNotify: EventEmitter<Object>;
	public votedUserAddedNotify: EventEmitter<Object>;
	public votedMovieAddedNotify: EventEmitter<Object>;
	private baseApi = environment.movie_night_endpoint;
	public loading: any;

	constructor(private alertController: AlertController, private loadingController: LoadingController,
		private http: Http, private localStorageService: LocalStorageService, private toastService: ToastService,
	  private router: Router) {
			this.votedNotify = new EventEmitter();
			this.votedUserAddedNotify = new EventEmitter();
			this.votedMovieAddedNotify = new EventEmitter();
		}

		public triggerRefreshFromNotification(){
			this.votedMovieAddedNotify.emit({vote_id: "doesnt matter"});
		}

		public addMovieToVote(movie) {
			var inputs = [];
			this.showLoading('Loading...').then(() => {
				this.getUserVotes().subscribe((response) => {
					console.log(response);
					let inputs = [];
					if(response.success){
						for(let vote of response.votes){
							if(vote.status == "New"){
								let input: any = {};
								input.name = vote.title
								input.value = vote.id;
								input.label = vote.title;
								input.type = 'radio';
								inputs.push(input);
							}
						}
						this.showVoteSelector(inputs, movie);
					}
					this.hideLoading();
				})
			})
		}

		public addFriendsToVote(vid) {
			var inputs = [];
			this.showLoading('Loading...').then(() => {
				this.getNotInvitedUsers(vid).subscribe((response) => {
					console.log(response);
					let inputs = [];
					if(response.success){
						for(let friend of response.connections){
							let input: any = {};
							input.name = decodeURI(friend.name);
							input.value = friend.id;
							input.label = decodeURI(friend.name);
							input.type = 'checkbox';
							inputs.push(input);
						}
						this.showFriendSelector(inputs, vid);
					}
					this.hideLoading();
				})
			})
		}

		public async showVoteSelector(inputs, movie){
			const alert = await this.alertController.create({
				header: 'Select vote:',
				inputs: inputs,
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							console.log('Confirm Cancel');
						}
					}, {
						text: 'Add',
						handler: (vid) => {
							if(vid != null){
								console.log(vid)
								this.addVoteMovie(vid, movie.original_title, movie.id, movie.poster_path, movie.backdrop_path).subscribe((response) => {
									if(response.success){
										this.toastService.shortToast("Added movie to vote.");
										this.votedMovieAddedNotify.emit({vote_id: vid});
									} else {
										this.toastService.shortToast(response.reason);
									}
								})
							} else {
								this.toastService.shortToast("Please select a vote to add to.");
								return false;
							}
						}
					}
				]
			});

			await alert.present();
		}

		public async showFriendSelector(inputs, vote_id){
			const alert = await this.alertController.create({
				header: 'Select friends:',
				inputs: inputs,
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							console.log('Confirm Cancel');
						}
					}, {
						text: 'Add',
						handler: (friend_ids) => {
							if(friend_ids != null) {
								for(let id of friend_ids){
									this.addVoteUser(vote_id, id).subscribe((response) => {
										console.log(response);
									})
								}
								this.votedUserAddedNotify.emit({vote_id: vote_id});
							}
						}
					}
				]
			});

			await alert.present();
		}

		public async showVoteWarning(vid, movie_id){
			const alert = await this.alertController.create({
				header: 'Submit vote?',
				message: 'Are you sure you want to vote for this movie?',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							console.log('Confirm Cancel');
						}
					}, {
						text: 'Yes',
						handler: () => {
							this.updateUserVote(vid, movie_id).subscribe((response) => {
								console.log("voted");
								this.votedNotify.emit({vote_id: vid});
							})
						}
					}
				]
			});

			await alert.present();
		}

		public async showDeleteWarning(vid, movie_id){
			const alert = await this.alertController.create({
				header: 'Remove movie from vote?',
				message: 'Are you sure you want to remove this movie from the vote?',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							console.log('Confirm Cancel');
						}
					}, {
						text: 'Yes',
						handler: () => {
							this.removeVoteMovie(vid, movie_id).subscribe((response) => {
								console.log("voted");
								this.votedNotify.emit({vote_id: vid});
							})
						}
					}
				]
			});

			await alert.present();
		}

		public async showDeleteUserWarning(vid, user_id){
			const alert = await this.alertController.create({
				header: 'Remove user from vote?',
				message: 'Are you sure you want to remove this user from the vote?',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							console.log('Confirm Cancel');
						}
					}, {
						text: 'Yes',
						handler: () => {
							this.removeVoteUser(vid, user_id).subscribe((response) => {
								this.votedNotify.emit({vote_id: vid});
							})
						}
					}
				]
			});

			await alert.present();
		}

		public async showVoteDeleteWarning(vid){
			const alert = await this.alertController.create({
				header: 'Delete this vote?',
				message: 'This will remove all users and movies from this vote and delete it.',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							console.log('Confirm Cancel');
						}
					}, {
						text: 'Yes',
						handler: () => {
							this.removeVote(vid).subscribe((response) => {
								this.toastService.shortToast("Vote deleted.");
								this.router.navigateByUrl('/movie-votes');
								this.votedNotify.emit({vote_id: vid});
							})
						}
					}
				]
			});

			await alert.present();
		}

		public getUserVotes() {
			return this.http.get(this.baseApi + "get_user_votes.php", { search: { u: this.localStorageService.get(environment.user_id_storage) } }).map((res: Response) => res.json())
		}

		public getNotInvitedUsers(vid) {
			return this.http.get(this.baseApi + "get_friends_to_add_vote.php", { search: { u: this.localStorageService.get(environment.user_id_storage), vid: vid } }).map((res: Response) => res.json())
		}

		public getVoteDetails(id){
			return this.http.get(this.baseApi + "get_vote_details.php", { search: { vid: id } }).map((res: Response) => res.json())
		}

		public updateUserVote(vid, movie_id){
			return this.http.get(this.baseApi + "update_user_vote.php", { search: { vid: vid, u: this.localStorageService.get(environment.user_id_storage), m: movie_id } }).map((res: Response) => res.json())
		}

		public updateVote(vid, status){
			return this.http.get(this.baseApi + "update_vote.php", { search: { vid: vid, status: status, u: this.localStorageService.get(environment.user_id_storage) } }).map((res: Response) => res.json())
		}

		public addVoteMovie(vid, title, mid, poster, backdrop){
			let dataParams = {
				vid: vid,
				m: mid,
				poster: poster,
				backdrop: backdrop,
				u: this.localStorageService.get(environment.user_id_storage),
				title: title
			}
			return this.http.get(this.baseApi + "add_vote_movie.php", { search: dataParams }).map((res: Response) => res.json())
		}

		public removeVoteMovie(vid, mid){
			let dataParams = {
				vid: vid,
				m: mid
			}
			return this.http.get(this.baseApi + "remove_vote_movie.php", { search: dataParams }).map((res: Response) => res.json())
		}

		public removeVoteUser(vid, u){
			let dataParams = {
				vid: vid,
				u: u
			}
			return this.http.get(this.baseApi + "remove_vote_user.php", { search: dataParams }).map((res: Response) => res.json())
		}

		public removeVote(vid){
			let dataParams = {
				vote_id: vid,
				u: this.localStorageService.get(environment.user_id_storage)
			}
			return this.http.get(this.baseApi + "delete_vote.php", { search: dataParams }).map((res: Response) => res.json())
		}

		public addVoteUser(vid, user_id){
			let dataParams = {
				vid: vid,
				u: user_id
			}
			return this.http.get(this.baseApi + "add_vote_user.php", { search: dataParams }).map((res: Response) => res.json())
		}

		public async hideLoading() {
			return await this.loading.dismiss();
		}

		private async showLoading(message) {
			this.loading = await this.loadingController.create({
				message: message
			});
			return await this.loading.present();
		}
	}
