import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MovieNightService } from '../services/movie-night.service';

@Component({
	selector: 'app-feedback',
	templateUrl: 'feedback.page.html'
})
export class FeedbackPage implements OnInit {
	public registerForm: FormGroup;
	public submitted = false;

	constructor(private formBuilder: FormBuilder, private movieNightService: MovieNightService) {}

	ngOnInit() {
		this.registerForm = this.formBuilder.group({
			feedback: ['', Validators.required]
		});
	}

	public get f() { return this.registerForm.controls; }
	
	public onSubmit() {

		if (this.registerForm.invalid) {
			return;
		}

		let message = this.f.feedback.value;

		this.movieNightService.sendFeedback(message).subscribe((response) => {
			this.submitted = true;
		});
	}
}
