import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionSheetController } from '@ionic/angular';
import { Router } from '@angular/router';
import { MovieNightService, ToastService, SearchService, ToolsService, ModalService, VoteService, PlaylistService } from '../services';


@Component({
	selector: 'app-search',
	templateUrl: 'search.page.html'
})
export class SearchPage implements OnInit {
	public filters = [
		{name:'Upcoming', value: 'upcoming'},
		{name:'Now Playing', value: 'now_playing'},
		{name:'Popular', value: 'popular'},
		{name:'Top Rated', value: 'top_rated'}
	];

	public search_type = [
		{name:'All', value: 'all'},
		{name:'Movies', value: "movies"},
		{name:'Actors', value: 'actors'}
	];
	public searchType = "all";
	public searchFilter = ""
	public searchTerm = "";
	public search_results = [];
	public searchComplete = false;
	public isPreSearch = false;

	constructor(private movieNightService: MovieNightService, private searchService: SearchService,
		private actionSheetController: ActionSheetController,
		private route: ActivatedRoute, private toastService: ToastService,
		private toolsService: ToolsService, public modalService: ModalService,
		private voteService: VoteService, private playlistService: PlaylistService) {}

		ngOnInit() {
			let preSearch = this.route.snapshot.paramMap.get('id');
			if(preSearch != null){
				this.searchTerm = preSearch;
				this.isPreSearch = true;
				this.search(null);
			}
		}

		public addMovie(movie){
			movie.already_added = true;
			this.toastService.shortToast(movie.title + " added to list.");
		}

		public deleteMovie(movie){
			movie.already_added = false;
			this.toastService.shortToast(movie.original_title + " was deleted.");
		}

		public search(input) {

			if(this.isPreSearch || (this.searchTerm != "" && input.keyCode == 13))
			{
				this.toolsService.showLoading('Searching...').then(() => {
					this.isPreSearch = false;
					if(this.searchType == "movies")
					{
						this.searchService.searchMovies(this.searchTerm).subscribe((response) => {
							this.search_results = [];

							for (let movie of response.movies) {
								movie._data.type = "movie";
								this.search_results.push(movie._data);
							}

							this.searchComplete = true;
							if(this.search_results.length == 0)
							this.searchComplete = false;
							this.toolsService.hideLoading();
						})
					}
					else if(this.searchType == "actors")
					{
						this.searchService.searchActors(this.searchTerm).subscribe((response) => {
							this.search_results = [];

							for (let actor of response.actors) {
								actor._data.type = "actor";
								this.search_results.push(actor._data);
							}

							this.searchComplete = true;
							if(this.search_results.length == 0)
							this.searchComplete = false;
							this.toolsService.hideLoading();
						})
					}
					else
					{
						this.searchService.searchMoviesAndActors(this.searchTerm).subscribe((response) => {
							this.search_results = [];

							if(response.actors.length > 0)
							{
								for (const {actor, index} of response.actors.map((actor, index) => ({ actor, index }))) {
									actor._data.type = "actor";
									actor._data.order_index = index;
									this.search_results.push(actor._data);
								}
							}

							if(response.movies.length > 0)
							{
								for (const {movie, index} of response.movies.map((movie, index) => ({ movie, index }))) {
									movie._data.type = "movie";
									movie._data.order_index = index;
									this.search_results.push(movie._data);
								}
							}

							this.search_results.sort(this.sortResults);
							this.searchComplete = true;
							if(this.search_results.length == 0)
							this.searchComplete = false;
							this.toolsService.hideLoading();
						})
					}
				})
			}
		}

		public searchList(){

			if(this.searchFilter != "")
			{
				this.toolsService.showLoading('Searching...').then(() => {
					this.searchService.searchList(this.searchFilter).subscribe((response) => {
						this.search_results = [];

						for (let movie of response.movies) {
							movie._data.type = "movie";
							this.search_results.push(movie._data);
						}

						this.searchComplete = true;
						if(this.search_results.length == 0)
						this.searchComplete = false;
						this.toolsService.hideLoading();
					})
				})
			}
		};

		private sortResults(a,b) {
			if (a.order_index < b.order_index)
				return -1;
			else if (a.order_index > b.order_index)
				return 1;
			else
				return 0;
		}
	}
