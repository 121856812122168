import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';

import { AddMoviesPlaylistModal } from './add-movies-playlist-modal.component';

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		ScrollingModule,
		ExperimentalScrollingModule,
		FormsModule,
		ReactiveFormsModule
	],
	declarations: [AddMoviesPlaylistModal],
	entryComponents: [
		AddMoviesPlaylistModal
	]
})
export class AddMoviesPlaylistModalModule {}
