import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../../environments/environment';

@Injectable()
export class MaintenenceGaurd implements CanActivate {

	constructor(public router: Router, private localStorageService: LocalStorageService) {}

	canActivate(): boolean {
		const maintenenceMode = this.localStorageService.get(environment.maintenence_mode_storage);
		if (maintenenceMode) {
			this.router.navigate(['maintenence']);
			return false;
		}
		return true;
	}
}
