import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { PipeModule } from '../../pipes/pipes.module';

import { ScheduleMovieNight } from './schedule-movie-night.component';

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		FormsModule,
		PipeModule,
		ReactiveFormsModule
	],
	declarations: [ScheduleMovieNight],
	entryComponents: [
		ScheduleMovieNight
	]
})
export class ScheduleMovieNightModule {}
