import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MovieNightService } from '../../services/movie-night.service';
import { ToolsService } from '../../services/tools.service';
import { ToastService } from '../../services/toast.service';

@Component({
	selector: 'app-add-playlist-modal',
	templateUrl: 'add-playlist-modal.html'
})
export class AddPlaylistModal implements OnInit {
	public newPlaylistForm: FormGroup;

	public infoLoaded = false;
	private loading: any;

	constructor(private modalController: ModalController, private formBuilder: FormBuilder,
		private movieNightService: MovieNightService, private toastService: ToastService,
		private toolsService: ToolsService) { }

		ngOnInit() {
			this.newPlaylistForm = this.formBuilder.group({
				title: ['', Validators.required],
				isPrivate: [false]
			});
		}

		public onPrivateChange(event){
			this.f.isPrivate.setValue(event.detail.checked);
		}

		public get f() { return this.newPlaylistForm.controls; }

		public onSubmit() {

			if(!this.newPlaylistForm.valid){
				return;
			}

			this.toolsService.showLoading('Loading playlist...').then(() => {
				this.movieNightService.addPlaylist(this.f.title.value, this.f.isPrivate.value ? '1' : '0').subscribe((response) => {
					this.movieNightService.playlistUpdates.emit(this.f.title.value);
					this.toolsService.hideLoading();
					this.closeModal();
				})
			})
		}

		public closeModal()
		{
			this.modalController.dismiss();
		}

	}
