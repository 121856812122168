import { Injectable } from '@angular/core';
import { Toast } from '@ionic-native/toast/ngx';

@Injectable()
export class ToastService {

	constructor(private toast: Toast) {}

	public shortToast(message){
		this.toast.show(message, '2000', 'bottom').subscribe(
			toast => {

			}
		);
	}
}
