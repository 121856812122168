import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AboutPage } from './about/about.page';
import { MaintenencePage } from './maintenence/maintenence.page';
import { LoginPage } from './login/login.page';
import { FeedbackPage } from './feedback/feedback.page';
import { SearchPage } from './search/search.page';
import { RecommendationsPage } from './recommendations/recommendations.page';
import { SubscriptionsPage } from './subscriptions/subscriptions.page';
import { ConnectionsPage } from './connections/connections.page';
import { UserProfilePage } from './connections/user-profile/user-profile.page';
import { MyMovies } from './my-movies/my-movies.page';
import { MyMovieNights } from './my-movie-nights/my-movie-nights.page';
import { MovieNightPage } from './my-movie-nights/movie-night/movie-night.page';
import { PlaylistPage } from './playlist/playlist.page';
import { NotificationsPage } from './notifications/notifications.page';
import { NewsFeedPage } from './news-feed/news-feed.page';
import { FindFriendsPage } from './find-friends/find-friends.page';
import { MyAccountPage } from './my-account/my-account.page';
import { EditSettingsPage } from './my-account/edit-settings/edit-settings.page';
import { ChangeInfoPage } from './my-account/change-info/change-info.page';
import { ComparePage } from './connections/compare/compare.page';
import { ActorProfilePage } from './search/actor-profile/actor-profile.page';
import { MyMovieVotes } from './movie-votes/my-movie-votes.page';
import { MovieVoteDetailsPage } from './movie-votes/movie-vote-details/movie-vote-details.page';
import { AuthGuard } from './services/authguard.service';
import { MaintenenceGaurd } from './services/maintenence-gaurd.service';

const routes: Routes = [
	{ path: '', redirectTo: '/movies/watch', pathMatch: 'full', canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'login', component: LoginPage, canActivate: [MaintenenceGaurd] },
	{ path: 'movies', component: MyMovies, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'news-feed', component: NewsFeedPage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'about', component: AboutPage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'maintenence', component: MaintenencePage },
	{ path: 'account', component: MyAccountPage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'account/edit-settings', component: EditSettingsPage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'account/change-info', component: ChangeInfoPage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'find-friends', component: FindFriendsPage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'subscriptions', component: SubscriptionsPage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'connections', component: ConnectionsPage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'connections/user-profile/:id', component: UserProfilePage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'connections/compare/:id', component: ComparePage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'feedback', component: FeedbackPage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'movie-nights', component: MyMovieNights, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'search', component: SearchPage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'search/actor/:id', component: ActorProfilePage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'notifications', component: NotificationsPage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'search/:id', component: SearchPage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'recommendations/:id', component: RecommendationsPage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'movie-nights/movie-night/:id', component: MovieNightPage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'playlist/:id', component: PlaylistPage, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'movie-votes', component: MyMovieVotes, canActivate: [AuthGuard, MaintenenceGaurd] },
	{ path: 'movie-votes/movie-vote-details/:id', component: MovieVoteDetailsPage, canActivate: [AuthGuard, MaintenenceGaurd] }
];
@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
