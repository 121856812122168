import { Component, ViewChildren, QueryList } from '@angular/core';
import { Platform, ModalController, ActionSheetController, PopoverController, IonRouterOutlet, MenuController } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { MembershipService } from './services/membership.service';
import { ToastService } from './services/toast.service';
// import { FcmService } from './services/fcm.service';
import { ModalService } from './services/modal.service';
import { VoteService } from './services/vote.service';
import { MovieNightService } from './services/movie-night.service';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../environments/environment';
import { AlertController, NavController } from '@ionic/angular';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx'

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html'
})
export class AppComponent {

	public imageOptions: CameraOptions = {
		allowEdit: false,
		quality: 50,
		targetWidth: 512,
		targetHeight: 512,
		correctOrientation: true,
		destinationType: this.camera.DestinationType.FILE_URI,
		sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
		mediaType: this.camera.MediaType.PICTURE
	}
	public isLoggedIn = false;
	public isIOS = false;
	public userImage = 'https://www.themovienightapp.com/movie_night_uploads/no-image.jpg';
	public appPages = [
		{
			title: 'Search',
			url: '/search',
			icon: 'search'
		},
		{
			title: 'My movies',
			url: '/movies/watch',
			icon: 'videocam'
		},
		{
			title: 'Movie Nights',
			url: '/movie-nights',
			icon: 'moon'
		},
		{
			title: 'Movie Votes',
			url: '/movie-votes',
			icon: 'bulb'
		},
		{
			title: 'Movie Night Activity',
			url: '/news-feed',
			icon: 'list'
		},
		{
			title: 'Subscriptions',
			url: '/subscriptions',
			icon: 'pulse'
		},
		{
			title: 'Friends',
			url: '/connections',
			icon: 'people-outline'
		},
		{
			title: 'Find Friends',
			url: '/find-friends',
			icon: 'person-add'
		},
		{
			title: 'My Account',
			url: '/account',
			icon: 'settings'
		},
		{
			title: 'Feedback',
			url: '/feedback',
			icon: 'send-outline'
		}
	];

	private lastTimeBackPress = 0;
	private timePeriodToExit = 2000;

	@ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private membershipService: MembershipService,
		private router: Router,
		private camera: Camera,
		private transfer: FileTransfer,
		private file: File,
		private localStorageService: LocalStorageService,
		private alertController: AlertController,
		private navController: NavController,
		private menu: MenuController,
		private popoverCtrl: PopoverController,
		private actionSheetCtrl: ActionSheetController,
		public modalCtrl: ModalController,
		// private fcm: FcmService,
		private route: ActivatedRoute,
		private toastService: ToastService,
		private modalService: ModalService,
		private movieNightService: MovieNightService,
		private network: Network,
		private voteService: VoteService,
		private ga: GoogleAnalytics
	) {
		this.initializeApp();
		if (membershipService.loggedInNotify.subscribe != null) {
			membershipService.loggedInNotify.subscribe(user => this.onLoginChange(user));
		}
		if(platform.is('ios')){
			this.isIOS = true;
		}
	}

	private notificationSetup() {
		if(this.platform.is('android') || this.platform.is('ios')){
			if(this.membershipService.isLoggedIn()){
				// this.fcm.getToken();
			}
			// this.fcm.onNotifications().subscribe(
			// (msg) => {
			// 	if(msg.body == "Vote finished!" || msg.body == "Vote tied!"){
			// 		this.voteService.triggerRefreshFromNotification();
			// 	}
			// 	if(msg.tap == "background"){
			// 		this.router.navigateByUrl('/notifications');
			// 	} else {
			// 		this.toastService.shortToast("New notification received");
			// 	}
			// });
		}
	}

	private initializeApp() {
		if(this.localStorageService.get(environment.dark_mode) != null && this.localStorageService.get(environment.dark_mode)){
			document.body.classList.toggle('dark', true);
		}
		this.platform.ready().then(() => {
			this.ga.startTrackerWithId('G-BY35NZQ6RP')
			.then(() => {
				console.log('Google analytics is ready now');
				// the component is ready and you can call any method here
				// this.ga.debugMode();
				// this.ga.setAllowIDFACollection(true);
			})
			.catch(e => console.log('Error starting GoogleAnalytics', e));
			this.isLoggedIn = this.membershipService.isLoggedIn();
			if(this.platform.is('android') || this.platform.is('ios')){
				this.statusBar.styleDefault();
				this.statusBar.overlaysWebView(false);
				this.splashScreen.hide();
			}
			this.notificationSetup();
			if(this.isLoggedIn){
				if(this.platform.is('android') || this.platform.is('ios')){
					this.membershipService.updateLastLogin();
				}
				this.membershipService.getUserSettings().subscribe((response) => {
					if(response.success){
						this.membershipService.setPublicProfile(response.public);
						this.membershipService.toggleAds(response.ads);
						if(this.platform.is('android') || this.platform.is('ios')){
							if(response.ads){
								// TODO ADS
							}
						}
					}
				})
			}
			this.router.events.subscribe((event: any) => {
				if(event instanceof NavigationEnd) {
					if(this.membershipService.isLoggedIn()){
						this.isLoggedIn = true;
						this.userImage = this.membershipService.getUserLocalPhoto();
						this.userImage = this.userImage.replace('http://', 'https://');
						console.log("user profile photo", this.userImage);
						if(event.url == "/login"){
							this.router.navigateByUrl('/movies/watch');
						}
					} else {
						this.isLoggedIn = false;
						this.router.navigateByUrl('/login');
					}
				}
			});
			this.startMaintenenceCheck();
			if(this.platform.is('android') || this.platform.is('ios')){
				this.setUpNetworkCheck();
			}
		});

	}

	public startMaintenenceCheck(){

		this.maintenenceCheck();
		setInterval(() => {
			this.maintenenceCheck();
		}, environment.periodic_checks_timer);
	}

	public maintenenceCheck(){
		this.movieNightService.checkIfApiDown().subscribe((response) => {
			if(response.status == "OK"){
				this.movieNightService.setMaintentenceMode(false);
				if(this.router.url == '/maintenence')
				this.router.navigateByUrl('/movies/watch');
			}
			else {
				this.movieNightService.setMaintentenceMode(true);
				this.router.navigateByUrl('/maintenence');
			}
		})
	}

	public setUpNetworkCheck(){
		let connectSubscription = this.network.onConnect().subscribe(() => {

		});

		let disconnectSubscription = this.network.onDisconnect().subscribe(() => {
			alert("Internet connection lost, app will now exit.")
			navigator['app'].exitApp(); // work in ionic 4
		});
	}

	public setNewPicture(){
		if(this.platform.is('android') || this.platform.is('ios')){
			this.camera.getPicture(this.imageOptions).then((imageURI) => {
				console.log("FOUND URI", imageURI);
				let options: FileUploadOptions = {
					fileKey: 'file',
					fileName: imageURI.substr(imageURI.lastIndexOf('/')+1),
					mimeType: "image/png",
					chunkedMode: false,
					params: new Object()
				}
				options.fileKey="file";
				options.fileName=imageURI.substr(imageURI.lastIndexOf('/')+1);
				options.mimeType="image/png";

				const fileTransfer: FileTransferObject = this.transfer.create();

				console.log("attempting upload", options)
				fileTransfer.upload(imageURI, "https://www.themovienightapp.com/upload-photo.php", options)
				.then((r) => {
					console.log("upload success?", r);
					let decodedResponse = JSON.parse(r.response);
					if(decodedResponse.success){
						this.membershipService.setUserPhoto(decodedResponse.path).subscribe((setResponse) => {
							if(setResponse.success){
								this.localStorageService.set(environment.user_photo_storage, decodedResponse.path);
								this.userImage = decodedResponse.path.replace("http://", "https://");
							}
						})
					}
				}, (err) => {
					// upload error
				})
			}, (err) => {
				// camera error
				console.log("ERROR")
				console.log(err)
			});
		}
	}

	public async askForImageUpload() {
		const alert = await this.alertController.create({
			header: 'Profile Picture',
			message: 'Do you want to upload a new profile picture?',
			buttons: [
				{
					text: 'Yes!',
					cssClass: 'primary',
					handler: () => {
						this.setNewPicture();
					}
				},
				{
					text: 'Cancel',
					role: 'cancel',
					cssClass: 'secondary',
					handler: (blah) => {

					}
				}
			]
		});

		await alert.present();
	}

	private onLoginChange(user: any): void {
		this.isLoggedIn = user.loggedIn;
		this.userImage = user.image;
		if(!user.loggedIn){
			this.router.navigateByUrl('');
		}
	}
}
