import { Component, OnInit } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { LocalStorageService } from 'angular-2-local-storage';
import { MembershipService, ToolsService } from '../../services';

@Component({
	selector: 'app-change-info',
	templateUrl: 'change-info.page.html'
})
export class ChangeInfoPage implements OnInit {
	public changeInfoForm: FormGroup;
	public submitted = false;
	public infoLoaded = false;
	public errors = {
		full_name: false,
		new_password: false,
		username: false
	}

	constructor(private formBuilder: FormBuilder, private membershipService: MembershipService,
		private loadingController: LoadingController, private toolsService: ToolsService) {}

		ngOnInit() {
			this.toolsService.showLoading('Loading info...').then(() => {
				this.membershipService.getAccountInfo().subscribe((response) => {
					;
					this.changeInfoForm = this.formBuilder.group({
						full_name: [response.name, Validators.required],
						email: [response.email, [Validators.required, CustomValidators.email]],
						username: [response.username, Validators.required],
						new_pw: [''],
						pw_confirm: ['']
					});

					this.infoLoaded = true;
					this.toolsService.hideLoading();
				})
			})
		}

		public get f() { return this.changeInfoForm.controls; }

		public onSubmit() {

			if(this.f.full_name.value.length < 3)
			{
				this.errors.full_name = true;
				return;
			}

			this.errors.full_name = false;

			if(this.f.new_pw.value.length < 6 || this.f.pw_confirm.value.length < 6 || (this.f.pw_confirm.value != this.f.new_pw.value))
			{
				if(!(this.f.new_pw.value.length == 0 && this.f.pw_confirm.value.length == 0))
				{
					this.errors.new_password = true;
					return;
				}
			}

			this.errors.new_password = false;

			if(this.f.username.value.length < 6 || this.f.username.value.indexOf(' ') >= 0)
			{
				if(!(this.f.username.value.length == 0))
				{
					this.errors.username = true;
					return;
				}
			}

			this.errors.username = false;

			if (this.changeInfoForm.invalid) {
				return;
			}

			this.toolsService.showLoading('Updating info...').then(() => {
				this.membershipService.updateAccountInfo(this.f.full_name.value, this.f.email.value, this.f.username.value, this.f.new_pw.value).subscribe((response) => {
					this.toolsService.hideLoading();
					if(response.success){
						alert(response.updates)
					}
				})
			})
		}
	}
