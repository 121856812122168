import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { LocalStorageService } from 'angular-2-local-storage';
import { AlertController, LoadingController } from '@ionic/angular';
import { ToastService } from './toast.service';
import { MovieNightService } from './movie-night.service';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class PlaylistService {
	public playlistNotify: EventEmitter<Object>;
	private baseApi = environment.movie_night_endpoint;
	public loading: any;

	constructor(private alertController: AlertController, private loadingController: LoadingController,
		private http: Http, private localStorageService: LocalStorageService, private toastService: ToastService, private movieNightService: MovieNightService) {
			this.playlistNotify = new EventEmitter();
		}

		public addMovieToPlaylist(movie) {
			var inputs = [];
			this.showLoading('Loading...').then(() => {
				this.movieNightService.getUserPlaylists().subscribe((response) => {
					let inputs = [];
					if(response.success){
						for(let playlist of response.playlists){
							let input: any = {};
							input.name = playlist.title
							input.value = playlist.playlist_id;
							input.label = playlist.title;
							input.type = 'radio';
							inputs.push(input);
						}
						this.showPlaylistSelector(inputs, movie);
					}
					this.hideLoading();
				})
			})
		}

		public async showPlaylistSelector(inputs, movie){
			const alert = await this.alertController.create({
				header: 'Select playlist:',
				inputs: inputs,
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							console.log('Confirm Cancel');
						}
					}, {
						text: 'Add',
						handler: (pid) => {
							if(pid != null){
								this.movieNightService.addMovieToPlaylist(movie.id, pid).subscribe((response) => {
									this.toastService.shortToast(movie.original_title + " added to playlist.")
								})
							} else {
								this.toastService.shortToast("Please select a vote to add to.");
								return false;
							}
						}
					}
				]
			});

			await alert.present();
		}

		public async hideLoading() {
			return await this.loading.dismiss();
		}

		private async showLoading(message) {
			this.loading = await this.loadingController.create({
				message: message
			});
			return await this.loading.present();
		}
	}
