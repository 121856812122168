import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { of } from 'rxjs';

@Injectable()
export class MovieNightService {
	public movieListUpdate: EventEmitter<string>;
	public playlistUpdates: EventEmitter<string>;
	private baseApi = environment.movie_night_endpoint;

	constructor(private http: Http, private localStorageService: LocalStorageService) {
		this.movieListUpdate = new EventEmitter();
		this.playlistUpdates = new EventEmitter();
	}

	public getMovieDetails(id){
		return this.http.get(this.baseApi + "get_movie_details.php", { search: { id: id } }).map((res: Response) => res.json())
	}

	public getMovieAndSubDetails(id){
		return this.http.get(this.baseApi + "get_movie_and_sub_details.php", { search: { id: id, u: this.localStorageService.get(environment.user_id_storage) } }).map((res: Response) => res.json())
	}

	public toggleMovieSubscription(id, decision){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			m: id,
			s: decision
		}
		return this.http.get(this.baseApi + "toggle_movie_subscription.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public getMovieNight(id: string): Observable<any> {
		return this.http.get(this.baseApi + "get_movie_night_details.php", { search: { movie_night_id: id, u: this.localStorageService.get(environment.user_id_storage) } }).map((res: Response) => res.json())
	}

	public getAllMovieNights(): Observable<any> {
		return this.http.get(this.baseApi + "get_user_movie_nights.php", { search: { u: this.localStorageService.get(environment.user_id_storage) } }).map((res: Response) => res.json())
	}

	public getUserMovieTotals(){
		return this.http.get(this.baseApi + "get_user_movie_totals.php", { search: { u: this.localStorageService.get(environment.user_id_storage) } }).map((res: Response) => res.json())
	}

	public getMovieRecommendations(id: string): Observable<any> {
		return this.http.get(this.baseApi + "get_movie_recommendations.php", { search: { mid: id, u: this.localStorageService.get(environment.user_id_storage) } }).map((res: Response) => res.json())
	}

	public getUserMovieList(watched){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			page: 0,
			sort: "Date Added",
			order: "DESC",
			watched: watched
		}

		if(this.localStorageService.get("movie_list_" + (watched == "True" ? "seen" : "watch")) != null){
			return of(this.localStorageService.get("movie_list_" + (watched == "True" ? "seen" : "watch"))).map(o => o);
		}

		return this.http.get(this.baseApi + "get_user_movie_list.php", { search: dataParams }).map((res: Response) => {
			this.localStorageService.set("movie_list_" + (watched == "True" ? "seen" : "watch"), res.json());
			return res.json()
		})
	}

	public getPossibleMoviesForPlaylist(id){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			playlist: id
		}
		return this.http.get(this.baseApi + "get_possible_playlist_additions.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public getPossibleFriendsForPlaylist(id){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			playlist: id
		}
		return this.http.get(this.baseApi + "get_users_not_on_playlist.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public getUserPlaylists(){
		return this.http.get(this.baseApi + "get_user_playlists.php", { search: { u: this.localStorageService.get(environment.user_id_storage) } }).map((res: Response) => res.json())
	}

	public getCompareList(id){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			fid: id
		}
		return this.http.get(this.baseApi + "get_user_movie_list_compare.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public addVote(title){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			title: title
		}
		return this.http.get(this.baseApi + "add_vote.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public getUserVotes(){
		return this.http.get(this.baseApi + "get_user_votes.php", { search: { u: this.localStorageService.get(environment.user_id_storage) } }).map((res: Response) => res.json())
	}

	public addPlaylist(title, isPrivate){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			title: title,
			private: isPrivate
		}
		return this.http.get(this.baseApi + "add_playlist.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public editPlaylist(title, isPrivate, playlist_id){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			title: title,
			playlist: playlist_id,
			private: isPrivate
		}
		return this.http.get(this.baseApi + "update_playlist.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public deletePlaylist(playlist_id){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			playlist: playlist_id
		}
		return this.http.get(this.baseApi + "delete_playlist.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public addMovieToPlaylist(movie_ids, playlist_id){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			movie: movie_ids,
			playlist: playlist_id
		}

		return this.http.get(this.baseApi + "add_playlist_movie.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public adduserToPlaylist(user_id, playlist_id){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			fid: user_id,
			playlist: playlist_id,
			edit: 0
		}
		return this.http.get(this.baseApi + "add_playlist_user.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public getPlaylistMovies(id){
		return this.http.get(this.baseApi + "get_playlist_movies.php", { search: { u: this.localStorageService.get(environment.user_id_storage), playlist: id } }).map((res: Response) => res.json())
	}

	public deletePlaylistMovie(playlist_id, movie_id){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			playlist: playlist_id,
			movie: movie_id
		}

		return this.http.get(this.baseApi + "delete_playlist_movie.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public getNotInvitedFriends(movie_night_id){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			mid: movie_night_id
		}

		return this.http.get(this.baseApi + "get_user_not_invited_friends.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public getUserNotifications(){
		return this.http.get(this.baseApi + "get_user_notifications.php", { search: { u: this.localStorageService.get(environment.user_id_storage) } }).map((res: Response) => res.json())
	}

	public getShowtimesByZip(zip, date){
		return this.http.get(this.baseApi + "get_showtimes.php", { search: { zip: zip, date: date } }).map((res: Response) => res.json())
	}

	public markNotificationAsRead(notif_id){
		return this.http.get(this.baseApi + "update_read_status.php", { search: { notif_id: notif_id } }).map((res: Response) => res.json())
	}

	public addMovie(movie, watched){

		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			title: movie.title != null ? movie.title : movie.original_title,
			m: movie.id,
			img: movie.poster_path,
			backdrop: movie.backdrop_path,
			rating: movie.vote_average,
			date: encodeURI(movie.release_date),
			watched: watched ? "True" : "False"
		}

		this.localStorageService.remove("movie_list_" + (watched ? "seen" : "watch"));
		return this.http.get(this.baseApi + "add_movie_to_list.php", { search: dataParams }).map((res: Response) => {
				this.movieListUpdate.emit(watched ? "seen" : "watch");
				return res.json();
		})
	}

	public addComment(movie_night_id, body){

		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			mid: movie_night_id,
			body: encodeURI(body)
		}

		return this.http.get(this.baseApi + "add_comment.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public addAttendee(friend_id, movie_night_id){

		let dataParams = {
			u: friend_id,
			mid: movie_night_id,
			rid: this.localStorageService.get(environment.user_id_storage)
		}

		return this.http.get(this.baseApi + "add_extra_attendee.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public updateMovieNight(movie_night_id, decision){
		let dataParams = {
			attendee_id: this.localStorageService.get(environment.user_id_storage),
			movie_night_id: movie_night_id,
			decision: decision
		}

		return this.http.get(this.baseApi + "update_movie_night_request.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public updateMovieStatus(movie_id, watched){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			m: movie_id,
			watched: watched
		}

		this.localStorageService.remove("movie_list_watch");
		this.localStorageService.remove("movie_list_seen");
		return this.http.get(this.baseApi + "update_watch_status.php", { search: dataParams }).map((res: Response) => {
			this.movieListUpdate.emit(watched ? "seen" : "watch");
			return res.json()
		})
	}

	public markMovieNightDelete(movie_night_id){
		let dataParams = {
			mid: movie_night_id,
			attendee: this.localStorageService.get(environment.user_id_storage)
		}

		return this.http.get(this.baseApi + "update_movie_night_watch_status.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public getActorProfile(actor_id){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			a: actor_id
		}

		return this.http.get(this.baseApi + "get_actor_info.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public getNewsFeed(type){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			type: type
		}

		return this.http.get(this.baseApi + "get_user_news_feed.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public deleteMovie(movie_id){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			m: movie_id
		}

		this.localStorageService.remove("movie_list_watch");
		this.localStorageService.remove("movie_list_seen");
		return this.http.get(this.baseApi + "delete_user_movie.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public requestMovieNight(movie_id, date, hour, minute, message, location){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			m: movie_id,
			date: date,
			hour: hour,
			minute: minute,
			message: message,
			location: location
		}

		return this.http.get(this.baseApi + "request_movie_night.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public editMovieNight(movie_night_id, date, hour, minute, location){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			m: movie_night_id,
			date: date,
			hour: hour,
			minute: minute,
			location: location
		}

		return this.http.get(this.baseApi + "edit_movie_night.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public sendNotification(type, reciever, reciever_fbid, sender_id, linked_id){

		let dataParams = {
			u: reciever,
			fbid: reciever_fbid,
			type: type,
			sender_id: sender_id,
			linked_id: linked_id
		}

		return this.http.get(this.baseApi + "send_notification.php", { search: dataParams }).map((res: Response) => { })
	}

	public sendFeedback(message){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			message: message
		}

		return this.http.get(this.baseApi + "send_feedback.php", { search: dataParams })
	}

	public checkIfApiDown(){
		return this.http.get("https://www.themovienightapp.com/app_status.php", { search: { u: this.localStorageService.get(environment.user_id_storage) } }).map((res: Response) => res.json())
	}

	public checkImdbUrl(url){
		return this.http.get(this.baseApi + "import_check.php", { search: { imbd: url } }).map((res: Response) => res.json())
	}

	public importImdbList(imdbId){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			imbd: imdbId
		}

		this.http.get(this.baseApi + "import_imbd_list.php", { search: dataParams }).subscribe((response) => {

		})
	}

	public addDonationRecord(amount, orderID, type){
		let dataParams = {
			u: this.localStorageService.get(environment.user_id_storage),
			amount: amount,
			type: type,
			order: orderID
		}
		return this.http.get(this.baseApi + "add_donation.php", { search: dataParams }).map((res: Response) => res.json())
	}

	public disableUserAds(){
		return this.http.get(this.baseApi + "update_user_ad_status.php", { search: { u: this.localStorageService.get(environment.user_id_storage) } }).map((res: Response) => res.json())
	}

	public setMaintentenceMode(toggle){
		this.localStorageService.set(environment.maintenence_mode_storage, toggle);
	}
}
